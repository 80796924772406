import Spinner from "../Spinner";

export default function ChartLoader({show}) {
  if (!show) return null;

  return (
    <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50'>
      <Spinner className='h-10'/>
    </div>
  );
}
