import debounce from "lodash.debounce";
import {useMemo} from "react";
import AutocompleteUserProfile from "./AutocompleteUserProfile";

export default function AutocompleteLocation({displayValue, onSelect}) {
  const getLocationData = useMemo(() => {
    return debounce((query, onComplete) => {
      if (!query) return onComplete && onComplete();

      fetch('/api/lookup/location', {
        method: 'POST',
        body: JSON.stringify({query}),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
      .then(r => r.json())
      .then((rawData) => {
        const data = rawData
        .map((p) => {
          return {
            label: p.fullName,
            value: p,
          };
        });

        onComplete(data);
      })
      .catch(() => {
        onComplete && onComplete();
      });
    }, 500, {trailing:  true});
  }, []);

  return (
    <AutocompleteUserProfile
      loadData={getLocationData}
      placeholder='Add your location'
      noDataPrompt='Type to add your city'
      displayValue={displayValue}
      onSelect={onSelect}
    />
  );
}
