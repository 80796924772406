import {ArrowLeftIcon, CheckCircleIcon, ChevronDownIcon} from "@heroicons/react/24/outline";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import useGetMe from "../hooks/useGetMe";
import usePerformInitNavigation from "../hooks/usePerformInitNavigation";
import useSocket from "../hooks/useSocket";
import {Button} from "../ui/Button";
import {Link, useLocation} from "wouter";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "../ui/Dropdown";
import USER_SETUP_STEPS from "./steps";
import useUserProfileCompletionCalculation from "./useUserProfileCompletionCalculation";
import {calculateCompletion} from "./utils";

export default function UserSetup() {
  const [socket] = useSocket();
  const {user, setup: {matchedProfile}} = useSelector(state => state.user);
  const containerRef = useRef(null);
  const [propsBag, setPropsBag] = useState({});
  const [stepIdx, setStepIdx] = useState();
  const [loaded, {firstIncompleteStep, stepCompletionStatus, percentage}] = useUserProfileCompletionCalculation(stepIdx);
  const performInitNavigation = usePerformInitNavigation();
  const [, navigate] = useLocation();
  const getMe = useGetMe();

  const Component = useMemo(() => user && USER_SETUP_STEPS[stepIdx]?.component, [stepIdx, user, USER_SETUP_STEPS]);
  const canGoBackTo = useMemo(() => stepIdx > 0 && USER_SETUP_STEPS[stepIdx]?.canGoBack(user, matchedProfile) ? stepIdx - 1 : -1, [stepIdx, user, matchedProfile]);
  const canSkip = useMemo(() => user && USER_SETUP_STEPS[stepIdx]?.canSkip && USER_SETUP_STEPS[stepIdx]?.canSkip(user), [user, stepIdx]);
  const nextIncompleteStep = useMemo(() => stepCompletionStatus.findIndex(([isComplete], statusStepIdx) => !isComplete && statusStepIdx > stepIdx), [stepIdx, stepCompletionStatus]);
  const activePercentage = useMemo(() => 100 / USER_SETUP_STEPS.length * (stepIdx) || 1, [stepIdx]);
  const willSaveAndExit = useMemo(() => !canSkip || stepIdx === USER_SETUP_STEPS.length - 1 || nextIncompleteStep < 0, [stepIdx, canSkip, nextIncompleteStep]);
  const canGoBack = canGoBackTo >= 0;

  useEffect(() => {
    if (!loaded) return;

    setStepIdx(firstIncompleteStep);
  }, [loaded, firstIncompleteStep]);

  const nextStep = useCallback((props = {}) => {
    return (forcedStep) => {
      if ((!forcedStep && stepIdx === USER_SETUP_STEPS.length - 1) || nextIncompleteStep < 0) {
        performInitNavigation();
      }

      getMe().then((me) => {
        const {percentage, stepCompletionStatus: statuses} = calculateCompletion(me, matchedProfile);
        if (!forcedStep && nextIncompleteStep < 0 && percentage === 100) {
          return;
        }

        setPropsBag({...propsBag, ...props});
        setStepIdx(forcedStep || nextIncompleteStep);
      });
    }
  }, [matchedProfile, getMe, stepIdx, nextIncompleteStep]);

  function reset(status = {}) {
    socket.emit('profiles:setup:reset', status, () => {
      window.location.reload();
    });
  }

  function goBack() {
    if (!stepIdx) {
      navigate('/');
      return;
    }

    if (!canGoBack) return;

    setStepIdx(canGoBackTo);
  }

  function scrollTop() {
    containerRef.current.scrollTo({top: 0, behavior: 'smooth'});
  }

  function saveAndExit() {
    getMe();
    performInitNavigation();
  }

  return (
    <div ref={containerRef} className='fixed bg-gray-800 z-50 top-0 left-0 h-full w-full flex flex-col overflow-y-auto'>
      {
        loaded && Component ? (
          <>
            {
              percentage < 100 ? (
                <>
                  <div className='sticky top-0 w-full bg-gray-800 font-semibold p-5 px-5 z-40' onClick={scrollTop}>Strengthen your account</div>
                  <div className='grow p-5 pt-0'>
                    <Component scrollTop={scrollTop} next={nextStep} {...propsBag} />
                  </div>
                  <div className='sticky bottom-0 w-full'>
                    <div className='h-1.5 w-full bg-gray-900'>
                      <div className={`bg-white ${activePercentage === 100 ? 'rounded-none' : 'rounded-full rounded-l-none'} h-full transition-all`} style={{width: `${activePercentage}%`}}></div>
                    </div>
                    <ul className='hidden flex items-center justify-center py-2 space-x-5 bg-yellow-800 list-disc'>
                      <li>
                        firstIncompleteStep {firstIncompleteStep}
                      </li>
                      <li>
                        canGoBackTo {canGoBackTo}
                      </li>
                      <li>
                        canSkip {canSkip ? 'YES' : 'No'}
                      </li>
                      <li>
                        nextIncompleteStep {nextIncompleteStep}
                      </li>
                      <li>
                        willSaveAndExit {willSaveAndExit ? 'YES' : 'No'}
                      </li>
                      <li>
                        stepCompletionStatus [{stepCompletionStatus.join(', ')}]
                      </li>
                      <li>
                        {percentage}%
                      </li>
                    </ul>
                    <div className='flex items-center justify-between bg-gray-600 p-5'>
                      {canGoBack ? <Button onClick={goBack} variant='ghost'>Back</Button> : <span></span> }
                      <Button
                        variant='ghost'
                        disabled={!willSaveAndExit && !canSkip}
                        onClick={() => {
                          if (willSaveAndExit) return saveAndExit();
                          if (canSkip) return nextStep()(nextIncompleteStep);
                        }}
                      >
                        {willSaveAndExit ? 'Save & exit' : 'Skip'}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <div className='absolute z-50 bg-gray-800 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                  <div className='flex flex-col items-center justify-center space-y-5'>
                    <div><CheckCircleIcon className='h-20' /></div>
                    <span className='font-semibold text-xl'>You're all done!</span>
                    <Link to='/' className='flex items-center'>
                      <ArrowLeftIcon className='h-5 mr-2' />
                      <span>Back to fuse</span>
                    </Link>
                  </div>
                </div>
              )
            }
          </>
        ) : (
          <div className='absolute z-50 bg-gray-800 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>Loading...</div>
        )
      }

      {
        user.role_type === 'admin' ? (
          <div className='fixed top-5 right-5 z-50 flex flex-col space-y-5'>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <ChevronDownIcon className='h-6' />
              </DropdownMenuTrigger>
              <DropdownMenuContent align='start' className='mr-5'>
                <DropdownMenuItem onClick={() => reset({})}>
                  Reset ALL
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => reset({step: 'PHONE_VER'})}>
                  Reset - Phone Verification
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        ) : null
      }
    </div>
  )
}
