import {CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import {useEffect, useMemo, useRef, useState} from "react";
import * as React from "react";
import useSocket from "../hooks/useSocket";
import Spinner from "../Spinner";
import {Button} from "../ui/Button";
import {Checkbox} from "../ui/Checkbox";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../ui/Command";
import {Input} from "../ui/Input";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/Popover";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../ui/Table";
import CSVReader from "react-csv-reader";
import {classNames} from "../utils/classes";

const mappings = JSON.parse(atob("eyJtYXBwZWRQcm9maWxlVG9Dc3ZDb2x1bW5zIjp7ImZpcnN0X25hbWUiOiJmaXJzdF9uYW1lIiwibGFzdF9uYW1lIjoibGFzdF9uYW1lIiwicG9zaXRpb24iOiJwcm9zcGVjdF9wb3NpdGlvbiIsImpvYl9yb2xlIjoicm9sZSIsImxvYl9zcGVjaWFsdHkiOiJsb2IiLCJjb21wYW55X25hbWUiOiJjb21wYW55X25hbWUiLCJpbmR1c3RyeV90eXBlIjoiaW5kdXN0cnlfdHlwZSIsImluc3VyYW5jZV9zZWdtZW50Ijoic2VnbWVudCIsIm1hcmtldCI6Im1hcmtldF8iLCJzdHJ1Y3R1cmUiOiJzdHJ1Y3R1cmUiLCJsb2NhdGlvbl9jaXR5IjoicHJvc3BlY3RfY2l0eSIsImxvY2F0aW9uX3N0YXRlIjoicHJvc3BlY3Rfc3RhdGUiLCJsb2NhdGlvbl9jb3VudHJ5IjoicHJvc3BlY3RfY291bnRyeSIsImNvbXBhbnlfY2l0eSI6ImNvbXBhbnlfY2l0eSIsImNvbXBhbnlfc3RhdGUiOiJjb21wYW55X3N0YXRlIiwiY29tcGFueV9jb3VudHJ5IjoiY29tcGFueV9jb3VudHJ5IiwiZW1haWwiOiJldmFib290X2VtYWlsIiwicG9zaXRpb25fZGVzY3JpcHRpb24iOiJwcm9zcGVjdF9wb3NpdGlvbl9kZXNjcmlwdGlvbiIsImJpbyI6InByb3NwZWN0X3N1bW1hcnkiLCJ0aXRsZSI6InByb3NwZWN0X2hlYWRsaW5lIn0sIm1hcHBlZENzdkNvbHVtbnNUb1Byb2ZpbGUiOnsiZmlyc3RfbmFtZSI6ImZpcnN0X25hbWUiLCJsYXN0X25hbWUiOiJsYXN0X25hbWUiLCJwcm9zcGVjdF9wb3NpdGlvbiI6InBvc2l0aW9uIiwicm9sZSI6ImpvYl9yb2xlIiwibG9iIjoibG9iX3NwZWNpYWx0eSIsImNvbXBhbnlfbmFtZSI6ImNvbXBhbnlfbmFtZSIsImluZHVzdHJ5X3R5cGUiOiJpbmR1c3RyeV90eXBlIiwic2VnbWVudCI6Imluc3VyYW5jZV9zZWdtZW50IiwibWFya2V0XyI6Im1hcmtldCIsInN0cnVjdHVyZSI6InN0cnVjdHVyZSIsInByb3NwZWN0X2NpdHkiOiJsb2NhdGlvbl9jaXR5IiwicHJvc3BlY3Rfc3RhdGUiOiJsb2NhdGlvbl9zdGF0ZSIsInByb3NwZWN0X2NvdW50cnkiOiJsb2NhdGlvbl9jb3VudHJ5IiwiY29tcGFueV9jaXR5IjoiY29tcGFueV9jaXR5IiwiY29tcGFueV9zdGF0ZSI6ImNvbXBhbnlfc3RhdGUiLCJjb21wYW55X2NvdW50cnkiOiJjb21wYW55X2NvdW50cnkiLCJldmFib290X2VtYWlsIjoiZW1haWwiLCJwcm9zcGVjdF9wb3NpdGlvbl9kZXNjcmlwdGlvbiI6InBvc2l0aW9uX2Rlc2NyaXB0aW9uIiwicHJvc3BlY3Rfc3VtbWFyeSI6ImJpbyIsInByb3NwZWN0X2hlYWRsaW5lIjoidGl0bGUifX0="));
const isDevelopment = process.env.NODE_ENV === 'development';

export function CsvImporter() {
  const resultScrollRef = useRef(null);
  const [csvFilename, setCsvFilename] = useState();
  const [csvData, setCsvData] = useState([]);
  const [mappedProfileToCsvColumns, setMappedProfileToCsvColumns] = useState(mappings.mappedProfileToCsvColumns);
  const [mappedCsvColumnsToProfile, setMappedCsvColumnsToProfile] = useState(mappings.mappedCsvColumnsToProfile);
  const [serializedMap, setSerializedMap] = useState('');
  const [csvColumnHeaders, setCsvColumnHeaders] = useState([]);
  const [uploadResults, setUploadResults] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [uploading, setUploading] = useState(false);
  const [allOrNothing, setAllOrNothing] = useState(false);
  const [profileColumns, setProfileColumns] = useState([]);
  const [profileUniqueColumns, setProfileUniqueColumns] = useState({});
  const [socket] = useSocket();

  useEffect(() => {
    setSerializedMap(btoa(JSON.stringify({mappedProfileToCsvColumns, mappedCsvColumnsToProfile})));
  }, [mappedProfileToCsvColumns, mappedCsvColumnsToProfile]);

  useEffect(() => {
    if (isDevelopment) {
      setMappedProfileToCsvColumns(mappings.mappedProfileToCsvColumns);
      setMappedCsvColumnsToProfile(mappings.mappedCsvColumnsToProfile);
    } else {
      setMappedProfileToCsvColumns({});
    }

    setUploadResults([]);
  }, [csvFilename]);

  useEffect(() => {
    socket?.emit('profiles:import:config', null, (columnSchema) => {
      setProfileColumns(columnSchema);
      setProfileUniqueColumns(calculateDups(columnSchema));
    });
  }, [socket?.connected]);

  const hasMappedColumns = useMemo(() => {
    return Object.keys(mappedProfileToCsvColumns).length > 0;
  }, [mappedProfileToCsvColumns]);

  const mappedCsvToProfileColumns = useMemo(() => {
    return Object.entries(mappedProfileToCsvColumns).reduce((acc, [profileColumn, csvColumn]) => {
      return {...acc, [csvColumn]: profileColumn};
    }, {});
  }, [mappedProfileToCsvColumns]);

  useEffect(() => {
    if (!Object.keys(mappedProfileToCsvColumns).length) return;

    const dups = {...calculateDups(profileColumns)};

    csvData.reduce((acc, row, rowIdx) => {
      Object.keys(profileUniqueColumns).forEach((uniqueCol) => {
        const value = row[mappedProfileToCsvColumns[uniqueCol]];

        if (!value) return;

        dups[uniqueCol][value] ||= [];
        dups[uniqueCol][value].push(rowIdx);
      });
    }, []);

    setProfileUniqueColumns(dups);
  }, [csvData, mappedProfileToCsvColumns]);

  const mappedCsvColumnsSet = useMemo(() => {
    return new Set(Object.values(mappedProfileToCsvColumns));
  }, [mappedProfileToCsvColumns]);

  function calculateDups(columnSchema) {
    return columnSchema
    .filter(col => col.unique)
    .reduce((acc, col) => {
      acc[col.value] = {};
      return acc;
    }, {});
  }

  function removeRow(idx) {
    const updatedCsvData = [...csvData];
    updatedCsvData.splice(idx, 1);
    setCsvData([...updatedCsvData]);
  }

  function lookup(targetCsvColumn) {
    const mapping = mappedCsvToProfileColumns[targetCsvColumn];
    if (!mapping) return;

    return profileColumns.find(({label, value}) => value === mapping);
  }

  function buildResult() {
    const uniqueColumns = {...profileUniqueColumns};
    const keys = csvColumnHeaders.filter((header) => {
      return lookup(header)?.label;
    });

    return csvData.map((row, rowIdx) => {
      const rowEntry = Object.entries(row);
      return rowEntry.reduce((acc, [key, rowValue], columnIdx) => {
        const profileColumnKey = mappedCsvToProfileColumns[key]
        if (!!uniqueColumns[profileColumnKey] && mappedCsvToProfileColumns[key]) {
          uniqueColumns[profileColumnKey][rowValue] ||= [];
          uniqueColumns[profileColumnKey][rowValue].push(rowIdx);
        }

        if (mappedCsvColumnsSet.has(key)) {
          return {
            ...acc,
            [key]: rowValue,
          }
        }

        return acc;
      }, {});
    });
  }

  async function sort(header) {
    const sortedCsvData = csvData.sort((left, right) => {
      console.log(header, left[header], right[header]);
      return left[header] > right[header] ? -1 : 1
    });

    console.log('sorting', csvData, sortedCsvData);

    setCsvData([...sortedCsvData]);
  }

  async function upload() {
    const results = buildResult();

    setUploading(true);
    setErrorMessage();

    window.fetch('/api/profiles/import', {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({allOrNothing, profiles: results, mappedCsvToProfileColumns}),
      method: 'POST',
    })
    .then(resp => resp.json())
    .then((resp) => {
      console.log(resp)
      const {success, msg, insertedProfileIds} = resp;

      if (!success) {
        setErrorMessage(msg);
        return;
      }

      setUploadResults(insertedProfileIds);
    }).finally(() => {
      setUploading(false);
    })

    return;

    socket.emit('profiles:import', {allOrNothing, profiles: results, mappedCsvToProfileColumns}, ({success, msg, insertedProfileIds}) => {
      setUploading(false);

      if (!success) {
        setErrorMessage(msg);
        return;
      }

      setUploadResults(insertedProfileIds);
    });
  }

  function removeMapping(profileColumnValue) {
    const newMappedProfileToCsvColumns = {...mappedProfileToCsvColumns};
    const newMappedCsvColumnsToProfile = {...mappedCsvColumnsToProfile};

    const csvColumnKey = newMappedProfileToCsvColumns[profileColumnValue];
    delete newMappedProfileToCsvColumns[profileColumnValue];
    delete newMappedCsvColumnsToProfile[csvColumnKey];

    setMappedProfileToCsvColumns(newMappedProfileToCsvColumns);
    setMappedCsvColumnsToProfile(newMappedCsvColumnsToProfile);
  }

  return (
    <div className='overflow-y-auto h-full'>
      <CSVReader onFileLoaded={(parsedData, fileInfo) => {
        const headers = Object.keys(parsedData[0]);

        parsedData = parsedData.map((row) => {
          const cleanRow = Object.entries(row).map(([key, val]) => {
            if (typeof val === 'string') {
              val = val.replace(/[^\x00-\x7F]/g, '')
              val = !val ? null : val;
            }

            return [key, val];
          });

          return Object.fromEntries(cleanRow);
        });

        setCsvFilename(fileInfo.name);
        setCsvColumnHeaders(headers);
        setCsvData(parsedData);
      }} parserOptions={{
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
      }} />
      {
        isDevelopment ? (
          <Input value={serializedMap} onChange={({target: {value}}) => {
            try {
              const {mappedProfileToCsvColumns, mappedCsvColumnsToProfile} = JSON.parse(atob(value));
              setMappedProfileToCsvColumns(mappedProfileToCsvColumns);
              setMappedCsvColumnsToProfile(mappedCsvColumnsToProfile);
            } catch (err) {}
          }} className='w-full p-2 bg-gray-800 mt-5' />
        ) : null
      }
      <div className='flex items-center justify-between'>
        <div className='w-full'>
          <div className='my-4'>Map Fields</div>
          <div className='grid grid-rows-7 grid-flow-col w-full gap-x-5 border rounded p-3'>
            {
              profileColumns.map(({label, required, value: profileColumnValue}, columnIdx) => {
                return (
                  <div className='flex items-center justify-between rounded hover:bg-gray-600 px-4'>
                    <div>{label} {required ? '*' : ''}</div>
                    <div className='flex items-center'>
                      {
                        mappedProfileToCsvColumns[profileColumnValue] ? (
                          <XCircleIcon onClick={() => removeMapping(profileColumnValue)} className='h-5' />
                        ) : null
                      }
                      <Popover>
                        <PopoverTrigger asChild>
                          <div className='p-3'>{mappedProfileToCsvColumns[profileColumnValue] || `Column ${columnIdx + 1}`}</div>
                        </PopoverTrigger>
                        <PopoverContent>
                          <Command>
                            <CommandInput placeholder='Column name' />
                            <CommandList>
                              <CommandEmpty>No results found.</CommandEmpty>
                              <CommandGroup>
                                <div className='space-y-1'>
                                  {
                                    csvColumnHeaders.map((csvColumnValue, optionIdx) => {
                                      const isSelected = mappedProfileToCsvColumns[profileColumnValue] === csvColumnValue;
                                      const isMapped = !!mappedCsvToProfileColumns[csvColumnValue];

                                      return (
                                        <CommandItem
                                          className={classNames(isSelected || isMapped ? 'bg-fuse-orange' : 'hover:bg-gray-700', 'rounded')}
                                          key={`${csvColumnValue}_${optionIdx}`}
                                          onSelect={() => {
                                            const newMappedCsvColumnsToProfile = {...mappedCsvColumnsToProfile};
                                            const newMappedProfileToCsvColumns = {...mappedProfileToCsvColumns};

                                            if (isMapped && !isSelected) {
                                              delete newMappedProfileToCsvColumns[newMappedCsvColumnsToProfile[csvColumnValue]];
                                              delete newMappedCsvColumnsToProfile[csvColumnValue];
                                            }

                                            setMappedCsvColumnsToProfile({
                                              ...newMappedCsvColumnsToProfile,
                                              [csvColumnValue]: profileColumnValue,
                                            });

                                            setMappedProfileToCsvColumns({
                                              ...newMappedProfileToCsvColumns,
                                              [profileColumnValue]: csvColumnValue,
                                            });
                                          }}
                                        >
                                          <div className='flex items-center justify-between w-full'>
                                            <span className='truncate' title={csvColumnValue}>{csvColumnValue}</span>
                                            <span>{mappedCsvToProfileColumns[csvColumnValue] ? 'Selected' : 'Unselected'}</span>
                                          </div>
                                        </CommandItem>
                                      )
                                    })
                                  }
                                </div>
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className='mt-10 grid grid-cols-2 space-x-5'>
        <div>
          <div className='flex justify-between'>
            <div>CSV Data</div>
            <div>{csvData.length} rows</div>
          </div>
          <div className='max-h-[400px] overflow-auto flex flex-col border rounded p-3 h-full'>
            {
              csvData.length ? (
                <div className="relative w-full overflow-auto" onScroll={(event) => {
                  resultScrollRef.current?.scroll({top: event.target.scrollTop, left: event.target.scrollLeft});
                }}>
                  <Table className='relative flex-grow'>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="w-[100px] bg-gray-800 sticky top-0 z-40"></TableHead>
                        {
                          csvColumnHeaders.map((header) => {
                            const mappedValue = lookup(header)?.label;

                            return (
                              <TableHead className={classNames(mappedValue ? 'bg-green-600 right-0 left-0 z-50' : 'bg-gray-800 z-40', 'sticky top-0')}>{mappedValue || header}</TableHead>
                            )
                          })
                        }
                        <TableHead className="w-[100px] bg-gray-800 sticky top-0 right-0 z-50"></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {
                        csvData.map((row, rowIdx) => {
                          const rowValues = Object.values(row);

                          return (
                            <TableRow className='group'>
                              <TableCell className="font-medium transition-colors group-hover:bg-gray-600 bg-gray-800 sticky left-0 z-40">{rowIdx + 1}</TableCell>
                              {
                                rowValues.map((rowValue, colIdx) => {
                                  const hasDups = false;

                                  return (
                                    <TableCell key={`${rowValue}_${rowIdx}_${colIdx}`} className={classNames(hasDups ? 'bg-red-500' : '', 'transition-colors group-hover:bg-gray-600 truncate min-w-[100px] max-w-[200px]')}>{rowValue}</TableCell>
                                  )
                                })
                              }
                              <TableCell className="transition-colors font-medium sticky right-0 group-hover:bg-gray-600 bg-gray-800">
                                <XCircleIcon className='h-5 w-5 hover:text-red-500' onClick={() => removeRow(rowIdx)}/>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div className='flex flex-col justify-center items-center h-full'>
                  <div>Select a file...</div>
                </div>
              )
            }
          </div>
        </div>

        <div>
          <div className='flex justify-between'>
            <div>Result Data</div>
            <div>{csvData.length} rows</div>
          </div>
          <div className='max-h-[400px] overflow-auto flex flex-col border rounded p-3 h-full'>
            {
              hasMappedColumns ? (
                <div ref={resultScrollRef} className="relative w-full overflow-auto">
                  <Table className={classNames('relative flex-grow')}>
                    <TableHeader className={!hasMappedColumns ? 'hidden' : 'h-full'}>
                      <TableRow>
                        <TableHead className="max-w-[100px] bg-gray-800 sticky top-0 left-0 z-50"></TableHead>
                        <TableHead className="w-[100px] bg-gray-800 sticky top-0 left-0 z-50"></TableHead>
                        {
                          csvColumnHeaders.map((header, colIdx) => {
                            const mappedProfileHeader = lookup(header);

                            if (!mappedProfileHeader) return null;

                            const hasDups = Object.values(profileUniqueColumns[mappedProfileHeader?.value] || {})?.some(val => val.length > 1);

                            return (
                              <TableHead
                                key={`${header}_${mappedProfileHeader?.value}_${colIdx}`}
                                onClick={() => sort(header)}
                                className={classNames(
                                  mappedProfileHeader ? 'bg-green-600 top-0 right-0 left-0 max-w-5' : 'bg-gray-800',
                                  hasDups ? 'bg-red-500' : '', 'sticky top-0'
                                )}
                              >
                                <div className='flex items-center space-x-2'>
                                  <span>{mappedProfileHeader?.label || header}</span>
                                  {
                                    hasDups ? (
                                      <Tippy content='This column has duplicates'>
                                        <QuestionMarkCircleIcon className='h-5 w-5' />
                                      </Tippy>
                                    ) : null
                                  }
                                </div>
                              </TableHead>
                            )
                          })
                        }
                        <TableHead className="w-[100px] bg-gray-800 sticky top-0 right-0 z-50"></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {
                        csvData.map((row, rowIdx) => {
                          const rowEntry = Object.entries(row);

                          return (
                            <TableRow key={`result_data_${rowIdx}`} className='group'>
                              <TableCell className="font-medium sticky left-0 transition-colors group-hover:bg-gray-600 bg-gray-800">{rowIdx + 1}</TableCell>
                              <TableCell className="font-medium sticky left-0 transition-colors group-hover:bg-gray-600 bg-gray-800">
                                {
                                  uploading ? (
                                    <Spinner className='h-5 w-5' />
                                  ) : (
                                    <>
                                      {
                                        Object.keys(uploadResults).length > 0 ? (
                                          uploadResults[rowIdx]?.success ? <CheckCircleIcon className='text-green-500 h-5 w-5' /> : (
                                            <>
                                              {
                                                uploadResults[rowIdx] ? (
                                                  <Tippy content={uploadResults[rowIdx]?.msg}>
                                                    <XCircleIcon className='text-red-500 h-5 w-5' />
                                                  </Tippy>
                                                ) : null
                                              }
                                            </>
                                          )
                                        ) : null
                                      }
                                    </>
                                  )
                                }
                              </TableCell>
                              {
                                rowEntry.map(([key, rowValue], idx) => {
                                  if (!mappedCsvColumnsSet.has(key)) return null;

                                  const profileColumnSchema = profileColumns.find((col) => mappedCsvToProfileColumns[key] === col.value);
                                  const error = !rowValue && profileColumnSchema.required;

                                  return (
                                    <TableCell key={`${rowValue}_${idx}`} className={classNames(profileColumnSchema?.required && (!rowValue || rowValue === '') ? 'bg-red-500': 'group-hover:bg-gray-600', 'transition-colors truncate min-w-[100px] max-w-[200px]')}>
                                      {
                                        error ? (
                                          <div className='flex items-center space-x-2'>
                                            <span className='italic'>Invalid value</span>
                                            <Tippy content='This field is required. You will not be able to import this row.'>
                                              <QuestionMarkCircleIcon className='h-5 w-5' />
                                            </Tippy>
                                          </div>
                                        ) : rowValue
                                      }
                                    </TableCell>
                                  )
                                })
                              }
                              <TableCell className="transition-colors font-medium sticky right-0 group-hover:bg-gray-600 bg-gray-800">
                                <XCircleIcon className='h-5 w-5 hover:text-red-500' onClick={() => removeRow(rowIdx)}/>
                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div className='flex flex-col justify-center items-center h-full'>
                  <div>Map columns to see the resulting profile data...</div>
                </div>
              )
            }
          </div>
        </div>
      </div>
      {errorMessage ? <div className='mt-10 bg-red-800 text-red-100 rounded p-5'>{errorMessage}</div> : null}
      <div className='flex justify-end mt-10 space-x-2'>
        <div className="flex items-center space-x-2">
          <Checkbox onCheckedChange={(checked) => setAllOrNothing(!!checked)} defaultChecked={allOrNothing} id="all_or_nothing"/>
          <label
            htmlFor="all_or_nothing"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Import all or nothing
          </label>
        </div>
        <Button onClick={upload}>Import</Button>
      </div>
    </div>
  )
}

export default function CsvImporterScreen() {
  useSocket();

  return (
    <div className='p-5'>
      <div className='text-lg font-semibold pb-5'>User Profile Importer</div>
      <CsvImporter />
    </div>
  )
}
