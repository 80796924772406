import logoLines from './assets/logo-lines.png';
import FuseLogoText from './FuseLogoText';

export default function Logo({withText = true}) {
  return (
    <div className="flex items-center">
      <img className="h-10" src={logoLines} alt="Logo" />
      {withText ? <FuseLogoText /> : null}
    </div>
  )
}
