import USER_SETUP_STEPS from "./steps";

export function calculateCompletion(user, matchingProfile) {
  const totalSteps = USER_SETUP_STEPS.length;
  const stepCompletionStatus = USER_SETUP_STEPS.map((s, idx) => ([s.isComplete(user, matchingProfile), idx]));
  const firstIncompleteStep = stepCompletionStatus.findIndex(([complete]) => !complete);
  const nextIncompleteStep = stepCompletionStatus.findIndex(([complete, idx]) => idx > firstIncompleteStep && !complete);
  const completeSteps = stepCompletionStatus.reduce((acc, [isComplete]) => isComplete ? acc + 1 : acc, 0);

  return {
    completeSteps,
    totalSteps,
    firstIncompleteStep,
    nextIncompleteStep,
    stepCompletionStatus,
    percentage: completeSteps / totalSteps * 100,
  };
}

export function savePhoneNumber(phone) {
  return fetch('/api/profiles/phone', {
    method: 'PATCH',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({phone}),
  })
  .then(resp => resp.json());
}
