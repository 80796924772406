import {PaperAirplaneIcon, PaperClipIcon} from "@heroicons/react/24/outline/index.js";
import {useContext, useRef, useState} from "react";
import ChannelsContext from "../Contexts/ChannelContext";
import { FileIcon, defaultStyles } from 'react-file-icon';
import useAppSelector from "../hooks/useAppSelector";
import useSocket from '../hooks/useSocket';
import Spinner from "../Spinner.jsx";
import RichTextEditor from './RichTextEditor';
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {BeautifulMentionNode} from "lexical-beautiful-mentions";
import {$generateHtmlFromNodes} from '@lexical/html';
import {$nodesOfType, CLEAR_EDITOR_COMMAND} from "lexical";
import {XCircleIcon} from "@heroicons/react/24/solid/index.js";
import ChannelContext from "../Contexts/ChannelContext";
import {classNames} from "../utils/classes";
import {useDropArea} from "react-use";

export default function Editor() {
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const {setAttachmentUuids} = useContext(ChannelContext);
  const [bond] = useDropArea({
    onFiles: files => processSelectedFiles(files),
  });

  const [socket] = useSocket();

  function openFiles() {
    fileInputRef.current.click();
  }

  function upload(file, putUrl, uuid) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.onload = function () {
        let status;
        if (xhr.status === 200) {
          status = 'uploaded';

          setTimeout(() => {
            socket.emit('upload:complete', {uuid}, ({attachment, confirmed}) => {
              resolve({attachment, confirmed, status});
            });
          }, 2000);
        } else {
          status = 'errored';
          reject({status});
        }
      };

      xhr.open('PUT', putUrl);
      xhr.send(file);
    });
  }

  async function processSelectedFiles(selectedFiles) {
    let filesToUpload = Array.from(selectedFiles).map((file) => ({
      file,
      uuid: null,
      uploadUrl: null,
      status: 'uploading',
    }));

    filesToUpload = [...filesToUpload, ...files];

    fileInputRef.current.value = '';

    setFiles(filesToUpload);

    for (let i = 0; i < filesToUpload.length; i++) {
      const fileToUpload = filesToUpload[i];

      if (fileToUpload.status === 'uploaded') continue;

      const {file} = fileToUpload;
      const idx = i;

      const {putUrl, uuid} = await socket.emitWithAck('upload:put', { filename: file.name, type: file.type });

      let newFiles = [...filesToUpload];
      newFiles[idx].status = "uploading";
      newFiles[idx].uploadUrl = putUrl;
      newFiles[idx].uuid = uuid;
      setFiles(newFiles);

      upload(file, putUrl, uuid).then(({status}) => {
        newFiles = [...filesToUpload];
        newFiles[idx].status = status;
        setFiles(newFiles);

        setAttachmentUuids((uuids) => [...uuids, uuid]);
      });
    }
  }

  function removeFile(idx) {
    const newFiles = [...files];
    newFiles.splice(idx, 1);
    setAttachmentUuids(newFiles.map(({uuid}) => uuid));
    setFiles(newFiles);
  }

  function sendMessage({message: rt, message_plain, channel_id, mentions, attachmentUuids}) {
    socket.emit('send_message', {message: rt, message_plain: message_plain, channel_id: channel_id, mentions, attachmentUuids}, () => {
      setAttachmentUuids([]);
      setFiles([]);
    });
  }

  return (
    <div {...bond}>
      <div className={classNames(files.length ? "mb-3" : '', 'flex justify-start items-center flex-wrap')}>
        {
          files.map(({file, status}, idx) => {
            const type = file.name.split('.').pop();

            return (
              <div className="flex items-center space-x-2 text-white truncate">
                <div className="h-4 w-4 shrink-0 mb-0.5">
                  { status === 'uploaded' && <FileIcon extension={type} {...defaultStyles[type]} /> }
                  { status === 'uploading' && <Spinner className="-ml-1 mr-3 h-5 w-5 text-white" /> }
                </div>
                <div title={file.name} className="truncate text-sm">{file.name}</div>
                <XCircleIcon onClick={() => removeFile(idx)} className="h-4 w-4 shrink-0 text-gray-500 hover:text-white transition cursor-pointer" />
              </div>
            );
          })
        }
      </div>
      <input type="file" multiple className="hidden" onChange={({target: {files}}) => processSelectedFiles(files)} ref={fileInputRef} />
      <div className="flex space-x-2 justify-end items-center">
        <div className="w-full">
          <RichTextEditor>
            <div className="bg-gray-700 rounded-b-xs p-1 text-white flex -mb-0.5 justify-between items-center w-full">
              <PaperClipIcon onClick={openFiles} className="h-5 w-5 p-0.5 hover:bg-gray-300 hover:text-gray-700 rounded transition-colors text-gray-200 cursor-pointer" />
              <SendMessage onSendMessage={sendMessage} />
            </div>
          </RichTextEditor>
        </div>
      </div>
    </div>
  )
}

export function SendMessage({sendingMessage, onSendMessage, icon}) {
  const {channelId} = useContext(ChannelsContext);
  const {attachmentUuids} = useContext(ChannelContext);
  const {hasDraftMessage} = useAppSelector(state => state.channels.adhocChannel);
  const [editor] = useLexicalComposerContext();

  function send() {
    const editorState = editor.getEditorState();

    editorState.read(() => {
      const html = $generateHtmlFromNodes(editor, null);

      const el = document.createElement('div');
      el.innerHTML = html;
      const plainText = el.innerText;

      const mentions = $nodesOfType(BeautifulMentionNode).reduce((acc, node) => {
        const nodeJson = node.exportJSON();
        acc[nodeJson.trigger] ||= [];
        acc[nodeJson.trigger].push(nodeJson?.data?.id);
        return acc;
      }, {});

      onSendMessage({message: html, message_plain: plainText, channel_id: channelId, mentions, attachmentUuids});
    });

    editor.update(() => {
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    });
  }

  return (
    <>
      {
        sendingMessage ? (
          <Spinner className="-ml-1 mr-3 h-5 w-5 text-white" />
        ) : (
          <button disabled={!hasDraftMessage} className="p-0.5 transition-colors hover:bg-green-700 focus:bg-green-700 " onClick={send}>
            {icon ? icon : <PaperAirplaneIcon className="h-5 w-5" /> }
          </button>
        )
      }
    </>
  );
}
