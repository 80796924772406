import {Column} from "@tanstack/react-table";
import {Profile, User} from "./types";
import {CSSProperties, useContext} from "react";
import {Button} from "../ui/Button";
import {ArrowsUpDownIcon, ArrowUpIcon} from "@heroicons/react/24/outline";
import * as React from "react";
import FilterContext from "../Contexts/FilterContext";

export const getCommonPinningStyles = (column: Column<Profile>): CSSProperties => {
  const isPinned = column.getIsPinned();

  return {
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
  }
}

export function sortableHeader(columnDisplayName: string) {
  return ({column}) => {
    const {toggleSorting} = useContext(FilterContext);
    const sortDirection = column.getIsSorted();

    return (
      <Button variant='ghost' className='flex items-center justify-start px-0 space-x-2' onClick={() => toggleSorting(column)}>
        <span>{columnDisplayName}</span>
        {
          !!sortDirection ? (
            <>
              {sortDirection === 'asc' ? <ArrowUpIcon className='w-4 h-4'/> : <ArrowUpIcon className='w-4 h-4 rotate-180'/>}
            </>
          ) : (
            <ArrowsUpDownIcon className="h-4 w-4" />
          )
        }
      </Button>
    );
  }
}
