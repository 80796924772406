import {createContext} from "react";
import {FilterState, Profile} from "../Switchboard/types";
import {Column} from "@tanstack/react-table";

const FilterContext = createContext<{
  filters?: any
  printingMarketReport?: boolean
  filterState: any
  setFilterState: (filterState: FilterState, shouldResetPagination?: boolean) => void
  toggleSorting: (column: Column<Profile>) => void
}>({
  filters: null,
  printingMarketReport: false,
  filterState: {},
  setFilterState: () => {},
  toggleSorting: () => {},
});

export default FilterContext;
