import {ChatBubbleLeftIcon} from "@heroicons/react/24/outline";
import {useMemo} from 'react';
import {useSelector} from "react-redux";
import {Link, useLocation, useParams} from "wouter";
import Chat from "../Chat";
import {SORT_OPERATIONS} from '../Dashboard/Header';
import useAppLocation from "../hooks/useAppLocation";
import {Button} from "../ui/Button";
import {classNames} from "../utils/classes";

function getChannelKey(activeSpaceId, channel) {
  return `${activeSpaceId}_${channel.id}`;
}

export default function ChatGrid({enableConversationSelector = false}) {
  const {user: {labels, hasLoaded}} = useSelector((state) => state.user);
  const {spaces} = useSelector((state) => state.spaces);
  const {filterText, filterLabels, sort} = useSelector(state => state.channelGrid);
  const channelsById = useSelector((state) => state.channels);
  const {activeSpaceId, channelId} = useParams();
  const [, {activeSpace}] = useAppLocation();
  const [, navigate] = useLocation();

  const channels = useMemo(() => {
    if (!activeSpaceId) return [];
    if (channelId) return [channelsById[channelId]];
    if (!spaces.length) return [];

    const space = spaces.find(space => space.id === activeSpaceId);

    if (!space) {
      const label = labels.find(label => label.uuid === activeSpaceId);

      if (!label) {
        navigate('/chat/all');
        return [];
      }

      return Object.values(channelsById).filter(c => {
        if (!c || !c.id) return false;

        return c.tags.map(({id}) => id).includes(label.id);
      });
    }

    return space.channels.map(channelId => channelsById[channelId]);
  }, [activeSpaceId, labels, channelsById, channelId]);

  const filteredChannels = useMemo(() => {
    const activeChannels = channels.filter((channel) => {
      if (!channel) return null;
      if (filterText && !channel.slug.toLowerCase().includes(filterText.toLowerCase())) return null;

      const targetFilterLabels = new Set(filterLabels);
      const channelLabels = new Set(channel.tags.map(({id}) => id));

      if (targetFilterLabels.size) return !!targetFilterLabels.intersection(channelLabels).size;

      return true;
    });

    if (!sort) return activeChannels;

    const {desc} = SORT_OPERATIONS[sort];

    const [after, before] = desc ? [1, -1] : [-1, 1];

    switch (sort) {
      case 'insertedAtDesc':
      case 'insertedAtAsc':
        return activeChannels.sort((a, b) => {
          return new Date(a.id) > new Date(b.inserted_at) ? before : after;
        });
      case 'latestMessageAsc':
      case 'latestMessageDesc':
        return activeChannels.sort((a, b) => {
          return new Date(a.latestMessageTimestamp || a.inserted_at) > new Date(b.latestMessageTimestamp || b.inserted_at) ? before : after;
        });
      case 'nameDesc':
      case 'nameAsc':
        return activeChannels.sort((a, b) => {
          if (a.metadata.name > b.metadata.name) return after;
          if (a.metadata.name < b.metadata.name) return before;
          return 0;
        });
    }

    return activeChannels;
  }, [filterText, filterLabels, channels, sort]);

  function renderEmptyState() {
    return (
      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full flex flex-col items-center justify-center'>
        <span className='ring-2 ring-white p-14 rounded-full mb-10'>
          <ChatBubbleLeftIcon className='h-20 stroke-1'/>
        </span>
        <div className='text-center text-lg'>No <strong>{activeSpaceId !== 'recent' ? activeSpace.name : ''}</strong> conversations yet.</div>
        <div className='text-center text-lg'>
          {activeSpaceId === 'recent' ? 'Start one by reaching out to a colleague!' : ''}
          {activeSpaceId === 'starred' ? 'Star important conversations for quick access later.' : ''}
          {activeSpaceId === 'muted' ? 'Mute less urgent conversations to focus on what matters.' : ''}
          {activeSpaceId === 'archived' ? 'Archive inactive conversations that may need referencing later.' : ''}
        </div>
        <div className='mt-5'>
          {
            activeSpaceId === 'recent' ? (
              <Link to='/chat/all'>
                <Button className='text-lg mx-auto' variant='ghost'>View recent conversations</Button>
              </Link>
            ) : (
              <Link to='/switchboard/people'>
                <Button className='mx-auto text-lg' variant='ghost'>Find people</Button>
              </Link>
            )
          }
        </div>
      </div>
    );
  }

  if (enableConversationSelector && !channelId) {
    return (
      <>
        {!filteredChannels.length ? renderEmptyState() : null}
        {
          filteredChannels.map((channel) => {
            return (
              <Link key={`channel_selector_${channel.id}`} to={`/chat/${activeSpaceId}/${channel.id}`}>
                <div className='p-4 hover:bg-gray-600 transition-colors'>{channel.slug}</div>
              </Link>
            );
          })
        }
      </>
    )
  }

  return (
    <>
      {hasLoaded && !filteredChannels.length ? renderEmptyState() : null}
      <div className='lg:p-6 sm:grid sm:gap-6 max-w-full sm:space-y-0 space-y-2 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 h-full'>
        {
          filteredChannels.map((channel) => {
            const channelKey = getChannelKey(activeSpaceId, channel);

            return (
              <div key={channelKey} className={classNames(enableConversationSelector ? 'h-full' : 'h-[35em]')}>
                <Chat channelId={channel.id} shrinkable={false}/>
              </div>
            )
          })
        }
      </div>
    </>
  );
};

