import {PencilSquareIcon} from "@heroicons/react/24/outline";
import {useRef, useState} from "react";
import useSocket from "../hooks/useSocket";
import UserAvatar from "../UserAvatar";
import {upload} from "./utils";

export default function UserAvatarForm({profile, onSave}) {
  const editAvatarFileInput = useRef(null);
  const [editAvatarOverlay, setEditAvatarOverlay] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(profile?.avatarUrl);
  const [socket] = useSocket();

  function editAvatar() {
    editAvatarFileInput.current.click();
  }

  async function uploadUserAvatar({target: {files}}) {
    const {url, key} = await socket.emitWithAck('user:avatar:put', null);
    await upload(url, files[0]);
    setAvatarUrl(key);
    onSave(key);
  }

  return (
    <div className='relative'>
      <input type="file" ref={editAvatarFileInput} onChange={uploadUserAvatar} className="hidden" accept="image/jpg, image/jpeg, image/png" />
      {
        editAvatarOverlay ? (
          <div onClick={() => editAvatar()} onMouseOut={() => setEditAvatarOverlay(false)} className="absolute h-32 w-32 rounded-full bg-gray-900/80 z-10 flex flex-col items-center justify-center">
            <PencilSquareIcon className="h-7 w-7 text-white pointer-events-none" />
          </div>
        ) : null
      }
      <div onMouseOver={() => setEditAvatarOverlay(true)}>
        <UserAvatar firstName={profile.firstName} lastName={profile.lastName} avatarUrl={profile.profilePictureUrl} className='text-3xl h-32 w-32 rounded-full ring-2 ring-white' />
      </div>
    </div>
  )
}
