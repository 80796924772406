import debounce from "lodash.debounce";
import {useMemo, useState} from "react";
import AutocompleteUserProfile from "./AutocompleteUserProfile";

export default function AutocompleteSector({defaultValue, onSelect: onValueSelected}) {
  const [data, setData] = useState([]);

  const getSectorData = useMemo(() => {
    if (data.length) {
      return (query, onComplete) => {
        new Promise((resolve) => {
          const response = query ? data.filter(d => d.value.toLowerCase().includes(query.toLowerCase())) : data;
          console.log(query, response);
          onComplete(response);
          resolve();
        });
      }
    }

    return debounce(async (query, onComplete) => {
      return fetch('/api/lookup/sector', {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
      .then(r => r.json())
      .then((data) => {
        const transformedData = data.map(({name}) => ({
          label: name,
          value: name,
        }));

        setData(transformedData);
        onComplete(transformedData);
      })
      .catch(() => {
        onComplete();
      });
    }, 500);
  }, [data]);

  function onSelect(value) {
    setData([]);
    onValueSelected(value);
  }

  return (
    <AutocompleteUserProfile
      title='Sector'
      onSelect={onSelect}
      loadData={getSectorData}
      displayValue={defaultValue}
      placeholder='Find your sector...'
      noDataPrompt='Search for your sector'
    />
  );
}
