export function getRgbString(colorJson) {
  colorJson ||= {r: 235, g: 179, b: 5}; // golden yellow
  return `rgb(${colorJson.r}, ${colorJson.g}, ${colorJson.b}, ${colorJson.a || 1})`;
}

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

const rgbToHex = (r, g, b) => {
  const toHex = (n) => n.toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export function selectColorOnGradient(ratio, color1 = '#FE6000', color2 = '#A855F7') {
  const [r1, g1, b1] = hexToRgb(color1);
  const [r2, g2, b2] = hexToRgb(color2);

  const r = Math.round(r1 + (r2 - r1) * ratio);
  const g = Math.round(g1 + (g2 - g1) * ratio);
  const b = Math.round(b1 + (b2 - b1) * ratio);

  return rgbToHex(r, g, b);
}
