import {useReactTable} from "@tanstack/react-table";
import {useCallback, useContext, useEffect, useState} from "react";
import {Link, useLocation, useParams} from "wouter";
import FilterContext from "../Contexts/FilterContext";
import {getDefaultReactTableConfig} from "../Data";
import useDataApi from "../hooks/useFetchDataApi";
import usePrint from "../hooks/usePrint";
import useReducedColumns from "../hooks/useReducedColumns";
import {Button} from "../ui/Button";
import DataTable from "../ui/DataTable";
import {downloadCsv, millionsAndBillionsFormatter} from "../utils";
import {selectColorOnGradient} from "../utils/colors";
import ChartArea from "./ChartArea";
import ChartBar from "./ChartBar";
import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  MagnifyingGlassPlusIcon,
} from "@heroicons/react/24/outline";
import {Card, CardContent, CardHeader, CardTitle} from "../ui/Card";
import {Dialog, DialogContent} from "../ui/Dialog";
import {classNames} from "../utils/classes";
import ChartErrorState from "./ChartErrorState";
import ChartLoader from "./ChartLoader";
import DropdownChartOptions from "./DropdownChartOptions";
import PrintSelectedFilters from "./PrintSelectedFilters";

const METRIC_NAME = 'brokerage-trend';

export default function BrokerageTrend({title}) {
  const {filterState, printingMarketReport} = useContext(FilterContext);
  const [chartType, setChartType] = useState('bar');
  const [config, setConfig] = useState({});
  const [data, setData] = useState([]);
  const [printContainerRef, printChart] = usePrint();
  const {metric} = useParams();
  const [, navigate] = useLocation();

  const [fetchDataApi, loading, errorMessage] = useDataApi();

  const getData = useCallback(() => {
    fetchDataApi('/api/data/charts/brokerage-trend')
      .then(({data}) => {
        const {years} = filterState;

        const transformed = data.reduce((acc, line) => {
          if (line.line_name === 'Totals') return acc;

          const row = {lineName: line.line_name};

          line.data.forEach((lineData) => {
            row[lineData.year] = parseFloat(Number.parseInt(lineData.total_brokerage_paid) / Number.parseInt(lineData.dir_prm_wrt_SUM) * 100).toFixed(2);
          });

          acc.push(row);

          return acc;
        }, [])
        .map((line) => {
          years.forEach((year) => {
            line[year] ||= 0;
          });

          return line;
        });

        const config = years.reduce((acc, year, idx) => {
          return {
            ...acc,
            [year]: {
              label: year,
              color: selectColorOnGradient(idx / years.length),
            }
          }
        }, {})

        setData(transformed);
        setConfig(config);
      });
  }, [filterState]);

  useEffect(() => {
    getData();
  }, [getData, filterState]);

  const columns = useReducedColumns('years', 'lineName', (value) => `${value}%`);

  const table = useReactTable({
    ...getDefaultReactTableConfig(),
    columns,
    data,
  });

  function formatter(val) {
    return `${val}%`;
  }

  function renderChart() {
    return (
      <div className={classNames(loading ? 'opacity-50' : '', 'mx-auto')}>
        {
          errorMessage ? (
            <>
              <ChartLoader show={loading} />
              <ChartErrorState message={errorMessage} reload={getData}/>
            </>
          ) : (
            <>
              <ChartLoader show={loading} />
              {chartType === 'bar' ? <ChartBar formatter={formatter} loading={loading} config={config} className='max-h-[350px] w-full' xAxisDataKey='lineName' title={title} data={data}/> : null}
              {chartType === 'area' ? <ChartArea formatter={formatter} loading={loading} config={config} className='max-h-[350px] w-full' xAxisDataKey='lineName' title={title} data={data}/> : null}
            </>
          )
        }
      </div>
    );
  }

  function doDownload() {
    downloadCsv(METRIC_NAME, data);
  }

  return (
    <Card ref={printContainerRef} className='bg-gray-700 print:bg-white print:shadow-none print:text-black print:mx-auto print:rounded-none print:border-none flex flex-col p-0 h-full print:w-4/5'>
      <PrintSelectedFilters show={!printingMarketReport} title={`${title} report`} />
      <CardHeader className='p-3'>
        <CardTitle className='flex print:justify-center justify-between items-center w-full text-md'>
          <div>{title}</div>
          <div className='flex items-center space-x-4 print:hidden'>
            <Dialog open={metric === METRIC_NAME} >
              <DialogContent disableClose className='w-full h-full flex flex-col justify-start max-w-full !rounded-none border-none'>
                <div className='flex flex-col justify-between overflow-y-auto overflow-x-hidden w-full md:mx-auto relative'>
                  <div className='flex items-center justify-between sticky z-50 top-0 bg-gray-800 w-full py-4 px-4'>
                    <div className='flex items-center justify-start space-x-5'>
                      <Link to='/data'>
                        <ArrowLeftIcon className='h-5'/>
                      </Link>
                      <strong>{title}</strong>
                    </div>
                    <Button onClick={doDownload} variant='link' className='flex items-center space-x-2 p-0' size='sm'>
                      <ArrowDownTrayIcon className='h-5'/>
                    </Button>
                  </div>
                  <div className='space-y-14 p-5 lg:w-1/2 w-full mx-auto'>
                    {renderChart()}
                    {loading ? null : <DataTable className='h-auto' table={table} />}
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <MagnifyingGlassPlusIcon className='h-5' onClick={() => navigate(`/data/metric/${METRIC_NAME}`)} />
            <DropdownChartOptions onPrint={printChart} onDownload={doDownload} metricName={METRIC_NAME} onChangeChartStyle={setChartType} chartTypes={['bar', 'area']} />
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className={classNames(loading ? 'opacity-50' : '', 'p-2 relative h-full transition-opacity min-h-[250px]')}>
        {renderChart()}
      </CardContent>
    </Card>
  );
}
