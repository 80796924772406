import {
  Bars3Icon, EyeIcon, EyeSlashIcon,
  PrinterIcon,
  SquaresPlusIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import {
  getCoreRowModel, getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import * as React from "react";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSessionStorage, useTitle} from "react-use";
import {Link} from "wouter";
import DashboardContext from "./Contexts/DashboardContext";
import FilterContext from './Contexts/FilterContext';
import BrokerageTrend from "./Data/BrokerageTrend";
import Dpe from "./Data/Dpe";
import DpeGrowth from "./Data/DpeGrowth";
import Dwp from "./Data/Dwp";
import LossRatio from "./Data/LossRatio";
import UsMap from "./Data/Map";
import PrintSelectedFilters from "./Data/PrintSelectedFilters";
import TableMarketPerformance from "./Data/TableMarketPerformance";
import useDataApi from "./hooks/useFetchDataApi";
import usePrint from "./hooks/usePrint";
import {setChartFilterState, setMarketFilterState} from "./reducers/dataSlice";
import Spinner from "./Spinner";
import {Button} from "./ui/Button";
import DataTableFacetedFilter from "./ui/DataTableFacetedFilter";
import {Dialog, DialogClose, DialogContent, DialogFooter, DialogTitle} from "./ui/Dialog";
import {Popover, PopoverContent, PopoverTrigger} from "./ui/Popover";
import {Skeleton} from "./ui/Skeleton";
import {classNames} from "./utils/classes";

import './Data/Chart.css';

export const CHART_FILTER_IDS = ['carrier', 'years', 'lob', 'states'];
const DEFAULT_COMMON_FILTERS = {years: [2021, 2022, 2023], lob: ['Fire', 'Allied lines', 'Commercial auto physical damage', 'Aircraft (all perils)', 'Surety']};
const DEFAULT_CHART_FILTERS = {...DEFAULT_COMMON_FILTERS};

export function getDefaultReactTableConfig() {
  return {
    manualFiltering: true,
    initialState: {},
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    getPaginationRowModel: () => null,
  }
}

const CHARTS = [
  {
    component: UsMap,
    title: 'DPE Map',
  },
  {
    component: Dpe,
    title: 'DPE millions',
  },
  {
    component: DpeGrowth,
    title: 'DPE Growth',
  },
  {
    component: Dwp,
    title: 'DWP Growth',
  },
  {
    component: LossRatio,
    title: 'Loss Ratio Trend',
  },
  {
    component: BrokerageTrend,
    title: 'Brokerage Trend',
  },
];

function computeChartVisibility(show) {
  return CHARTS.reduce((acc, c) => ({...acc, [c.title]: show}), {})
}

const DEFAULT_SHOWN_CHARTS = computeChartVisibility(true);

export default function Data() {
  useTitle('Fuse - Data');
  const graphFilterState = useSelector(state => state.dataApp.chart.filterState);
  const filterState = useSelector(state => state.dataApp.market.filterState);
  const [filters, setFilters] = useState({});
  const [showAppLoadingError, setShowAppLoadingError] = useState(false);
  const [loadDataApp, setLoadDataApp] = useState(false);
  const {toggleShowSidebar} = useContext(DashboardContext);
  const [shownCharts, setShownCharts] = useState(DEFAULT_SHOWN_CHARTS);
  const [showWelcomeMessage, setShowWelcomeMessage] = useSessionStorage('data-welcome-message', true);
  const [printingMarketReport, setPrintingMarketReport] = useState(false);
  const dispatch = useDispatch();
  const [fetchDataApi, loading, apiErrorMessage] = useDataApi();
  const [marketReportPrintRef, printMarketReport] = usePrint({
    onAfterPrint: () => {
      setPrintingMarketReport(false);
    },
  });

  useEffect(() => {
    if (!apiErrorMessage) return;

    setShowAppLoadingError(true);
  }, [apiErrorMessage]);

  const loadFilters = useCallback((skipCache = false) => {
    setShowAppLoadingError(false);
    setLoadDataApp(false);
    setFilters({});

    fetchDataApi(`/api/data/filters${skipCache ? '?skipCache=true' : ''}`, 'GET')
    .then(({data}) => {
      if (!data?.filters) {
        setShowAppLoadingError(true);
        return;
      }

      setFilters(data.filters);
      setLoadDataApp(true);
    });
  }, [fetchDataApi]);

  useEffect(() => {
    loadFilters();
  }, [fetchDataApi]);

  const visibleCharts = useMemo(() => CHARTS.filter(c => shownCharts[c.title]), [shownCharts]);
  const hasShownCharts = useMemo(() => Object.entries(shownCharts).filter(([, shown]) => shown).length, [shownCharts]);
  const hasHiddenCharts = useMemo(() => Object.entries(shownCharts).filter(([, shown]) => !shown).length, [shownCharts]);

  function showAllCharts() {
    setShownCharts(DEFAULT_SHOWN_CHARTS);
  }

  function hideAllCharts() {
    setShownCharts(computeChartVisibility(false));
  }

  return (
    <FilterContext.Provider value={{
      filters,
      printingMarketReport,
      filterState: graphFilterState,
      setFilterState: (newFilterState) => {
        dispatch(setChartFilterState(newFilterState));
      }
    }}>
      <div className='flex flex-col justify-between bg-gray-800 text-lg font-semibold py-5 px-4'>
        <Dialog open={showAppLoadingError} onOpenChange={setShowAppLoadingError}>
          <DialogContent disableClose className='p-5'>
            <DialogTitle>Fuse Data</DialogTitle>
            <div>
              Fuse Data could not load
            </div>
            <div>
              We had an issue loading Fuse Data. Would you like to try again?
            </div>
            <pre className='text-sm text-center text-red-500 my-5'>{apiErrorMessage}</pre>
            <DialogFooter>
              <Link to='/'>
                <Button variant='ghost'>Never mind</Button>
              </Link>
              <Button onClick={() => loadFilters(true)}>Yes, please</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <Dialog open={showWelcomeMessage} onOpenChange={setShowWelcomeMessage}>
          <DialogContent disableClose className='p-5'>
            <DialogTitle>Fuse Data</DialogTitle>
            <div>
              Fuse Data is currently in preview
            </div>
            <div>
              This application is under active development. The data is sourced from the NAIC and may have errors during this phase.
            </div>
            <DialogFooter>
              <DialogClose>
                <Button>I got it</Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <div className='flex items-center pb-4'>
          <div className='lg:hidden lg:mr-0 mr-3 hidden' onClick={() => toggleShowSidebar()}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon role='button' className="h-7 w-7" aria-hidden="true"/>
          </div>
          <div className='flex items-center justify-between w-full'>
            <div className='flex items-center space-x-2'>
              <div>Data</div>
              <div className='bg-purple-500 rounded-full text-xs px-1.5 py-0.5 font-normal'>Preview</div>
            </div>
            <div className='flex items-center space-x-4'>
              <Popover>
                <PopoverTrigger>
                  <SquaresPlusIcon className='h-5'/>
                </PopoverTrigger>
                <PopoverContent sideOffset={5} alignOffset={10} className='p-2 text-sm'>
                  {
                    hasShownCharts ? (
                      <>
                        <div className='flex items-center justify-between'>
                          <div>Shown</div>
                          <Button onClick={hideAllCharts} className='font-semibold pr-0' variant='ghost'>Hide all</Button>
                        </div>
                        <div className='space-y-3 py-3'>
                          {
                            Object.entries(shownCharts).map(([title, shown]) => {
                              if (!shown) return null;

                              return (
                                <div key={`chart_visibility_${title}`} className='flex items-center justify-between'>
                                  <div>{title}</div>
                                  <div onClick={() => {
                                    setShownCharts({
                                      ...shownCharts,
                                      [title]: !shown,
                                    })
                                  }}>
                                    {shown ? <EyeIcon className='h-5'/> : <EyeSlashIcon className='h-5'/>}
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </>
                    ) : null
                  }
                  {
                    hasHiddenCharts ? (
                      <>
                        <div className='flex items-center justify-between'>
                          <div>Hidden</div>
                          <Button className='font-semibold pr-0' variant='ghost' onClick={showAllCharts}>Show all</Button>
                        </div>
                        <div className='space-y-3 py-3'>
                          {
                            Object.entries(shownCharts).map(([title, shown]) => {
                              if (shown) return null;

                              return (
                                <div key={`chart_visibility_${title}`} className='flex items-center justify-between'>
                                  <div>{title}</div>
                                  <div onClick={() => {
                                    setShownCharts({
                                      ...shownCharts,
                                      [title]: !shown,
                                    })
                                  }}>
                                    {shown ? <EyeIcon className='h-5'/> : <EyeSlashIcon className='h-5'/>}
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </>
                    ) : null
                  }
                  <div></div>
                </PopoverContent>
              </Popover>
              <button disabled={!hasShownCharts} className='disabled:opacity-50 disabled:cursor-not-allowed'>
                {
                  printingMarketReport ? (
                    <Spinner className='h-5' />
                  ) : (
                    <PrinterIcon className='h-5' onClick={() => {
                      setPrintingMarketReport(true);
                      setTimeout(printMarketReport, 200);
                    }} />
                  )
                }
              </button>
            </div>
          </div>
        </div>
        <div className='font-normal'>Market overview</div>
        <div className='flex flex-wrap items-center gap-2 mt-2'>
          {
            CHART_FILTER_IDS.map((id) => {
              if (!filters[id]) {
                if (!loading) return null;

                return <Skeleton key={`skel_${id}`} className='h-8 w-32 rounded' />;
              }

              return (<DataTableFacetedFilter key={`filter_${id}`} columnName={id.toLowerCase()} title={filters[id].label} options={filters[id].data} />);
            })
          }
          {
            loadDataApp ? (
              <Button
                variant="ghost"
                className="h-8 px-2 lg:px-3 flex items-center"
                onClick={() => setGraphFilterState({...DEFAULT_CHART_FILTERS})}
              >
                <span>Reset</span>
                <XMarkIcon className="ml-2 h-4 w-4"/>
              </Button>
            ) : null
          }
        </div>

        <div className='space-y-8' ref={marketReportPrintRef}>
          <PrintSelectedFilters title='Market report' show={printingMarketReport} />
          <div className='grid print:grid-cols-2 xl:grid-cols-6 md:grid-cols-2 grid-cols-1 gap-8 mt-4 print:mt-0 print:px-2'>
            {
              !hasShownCharts ? (
                <div className='flex justify-start flex-col items-center w-full col-span-full space-y-4 my-10'>
                  <div className='col-span-full text-center'>All charts are hidden</div>
                  <div className='col-span-full text-center flex items-center justify-center space-x-2'>
                    <span>Use the </span>
                    <SquaresPlusIcon className='h-5' />
                    <span>to show charts</span>
                  </div>
                </div>
              ) : null
            }
            {
              visibleCharts.map(({component: Component, title}, idx) => {
                const isLast = idx === visibleCharts.length - 1 && visibleCharts.length % 2 === 1 && visibleCharts.length >= 4;

                return (
                  <div key={`chart_${title}`} className={classNames(isLast ? 'sm:col-span-full' : 'xl:col-span-3')}>
                    {loadDataApp ? <Component title={title} /> : <Skeleton className='h-72 w-full rounded' />}
                  </div>
                )
              })
            }
          </div>
          <div className='text-sm text-gray-300 text-right print:mr-5'>* Data provided by the NAIC</div>
        </div>

        <FilterContext.Provider value={{
          filterState,
          setFilterState: (newFilterState) => {
            dispatch(setMarketFilterState(newFilterState));
          }
        }}>
          <div className='mt-5 font-normal'>
            <div className='font-normal'>Market performance</div>
            <TableMarketPerformance filters={filters} />
          </div>
        </FilterContext.Provider>
      </div>
    </FilterContext.Provider>
  )
}
