import {ErrorMessage, Field, Formik, useField} from "formik";
import {useEffect, useState} from "react";
import {PhoneInput} from "react-international-phone";
import {useSearchParam} from "react-use";
import validator from "validator";
import {useLocation} from "wouter";
import * as Yup from 'yup';
import Spinner from "./Spinner";
import AutocompleteCompany from "./Autocomplete/AutocompleteCompany";
import {classNames} from "./utils/classes";
import {passwordAddition} from "./utils/validation";
import Logo from "./Logo";
import 'react-international-phone/style.css';
import './Signup.css';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(30, 'Cannot be longer than 30')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Must be at least 2 characters')
    .max(30, 'Cannot be longer than 30')
    .required('Required'),
  email: Yup.string()
    .test('is-email', 'Invalid email', (value) => {
      return validator.isEmail(value);
    })
    .required('Required'),
  company: Yup.mixed().required('Company is a required field'),
  phone: Yup.string()
    .test('is-phone', 'Invalid mobile phone number', (value) => {
      return validator.isMobilePhone(value);
    })
    .required('Required'),
  password1: passwordAddition,
  password2: passwordAddition,
  termsofservice: Yup.string().oneOf(['true'], 'You must agree to the Terms of Service'),
});

function SignupForm({submitting, initialValues, isInvitedSignup = false, onSubmit}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={SignupSchema}
    >
      {({handleSubmit, setFieldValue, values}) => {
        return (
          <form id="signup_form" onSubmit={handleSubmit} className="w-full">
            <div className="w-full">
              <div className="gap-y-6 space-y-4">
                <div>
                  <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-white">
                    First name *
                  </label>
                  <div className="">
                    <Field type="text" name="firstName" placeholder="First name..." className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6" />
                    <ErrorMessage name="firstName">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div>
                  <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-white">
                    Last name *
                  </label>
                  <div className="">
                    <Field type="text" name="lastName" placeholder="Last name..." className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"/>
                    <ErrorMessage name="lastName">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">
                    {isInvitedSignup ? 'Invitation' : 'Business'} email *
                  </label>
                  <div className="">
                    <Field type="email" name="email" disabled={isInvitedSignup} placeholder={`Your ${isInvitedSignup ? 'invitation' : 'personal'} email... me@gmail.com`} autoComplete="email" className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"/>
                    <ErrorMessage name="email">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label htmlFor="phone" className="block text-sm font-semibold leading-6 text-white">
                    Mobile phone *
                  </label>
                  <div>
                    <Field type="tel" name="phone" validateOnChange={false} component={IntlPhoneNumber} autoComplete="tel-country-code" className="w-full flex rounded-md border-0 bg-white/5 px-2.5 py-0.5 focus:shadow-none text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus-within:ring-orange-500 sm:text-sm sm:leading-6" />
                    <ErrorMessage name="phone">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="company" className="block text-sm font-semibold leading-6 text-white">
                    Company *
                  </label>
                  <div>
                    <AutocompleteCompany
                      className='inline-flex justify-between w-full bg-gray-800 rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6'
                      displayValue={values.company?.companyName || values.company}
                      onCreate={(value) => {
                        return setFieldValue('company', value, false);
                      }}
                      onSelect={(value) => {
                        return setFieldValue('company', value ? value : '', false);
                      }}
                    />
                    <Field type="hidden" required={false} name="company" placeholder="Your company name... [optional]" />
                    <ErrorMessage name="company">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div>
                  <label htmlFor="password1" className="block text-sm font-semibold leading-6 text-white">
                    Password *
                  </label>
                  <div className="">
                    <Field type="password" placeholder="**********" name="password1" autoComplete="new-password" className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"/>
                    <ErrorMessage name="password1">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div>
                  <label htmlFor="password2" className="block text-sm font-semibold leading-6 text-white">
                    Confirm password *
                  </label>
                  <div className="">
                    <Field type="password" placeholder="**********" name="password2" autoComplete="new-password" className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-orange-500 sm:text-sm sm:leading-6"/>
                    <ErrorMessage name="password2">
                      {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                    </ErrorMessage>
                  </div>
                </div>
                <div>
                  <div className="relative flex gap-x-3 col-span-full">
                    <div className="flex items-center">
                      <Field type="checkbox" name="termsofservice" className="h-4 w-4 rounded border-white/80 bg-white/90 text-orange-600 focus:ring-orange-600 focus:ring-offset-gray-900"/>
                    </div>
                    <div className="text-sm leading-6 flex justify-between w-full">
                      <p className="text-gray-100 font-semibold">
                        I agree to the
                        {' '}
                        <a target="_blank" href="https://fuserisk.com/terms" className="hover:underline text-gray-100 font-semibold">Terms of Service</a>
                      </p>
                    </div>
                  </div>
                  <ErrorMessage name="termsofservice">
                    {(msg) => (<span className="text-xs text-red-500">{msg}</span>)}
                  </ErrorMessage>
                </div>
              </div>
              <div className="mt-8 flex items-center justify-between">
                <button
                  disabled={submitting}
                  type="submit"
                  className="flex items-center space-x-2 disabled:opacity-50 rounded-md bg-orange-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                >
                  {submitting ? <Spinner className="h-5 w-5"/> : null}
                  <div>{submitting ? 'Signing Up!' : 'Create account'}</div>
                </button>
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export default function Signup() {
  const [, navigate] = useLocation();
  const [invitationData, setInvitationData] = useState(null);
  const [signingUp, setSigningUp] = useState(false);
  const [responseMsg, setResponseMessage] = useState(null);
  const [userCreationSuccess, setUserCreationSuccess] = useState(null);

  const inviteToken = useSearchParam('invite');
  const [loadingInviteToken, setLoadingInviteToken] = useState(!!inviteToken);

  useEffect(() => {
    if (!inviteToken) return;

    setLoadingInviteToken(true);
    fetch(`/api/signup?invite_token=${inviteToken}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((resp) => resp.json())
    .then(({success, msg, invitation}) => {
      if (success) return setInvitationData(invitation);

      setLoadingInviteToken(false);
      setResponseMessage(msg);
      setUserCreationSuccess(success);
      navigate('/signup', {replace: true});
    })
    .finally(() => {
      setTimeout(() => setLoadingInviteToken(false), 1000);
    });
  }, [inviteToken]);

  function handleSignup(data, formFunctions) {
    setResponseMessage(null);

    const {setFieldError, resetForm} = formFunctions;

    if (data.password1 !== data.password2) {
      setFieldError("password2", 'Passwords must match.');
      return;
    }

    setSigningUp(true);

    fetch('/api/signup', {
      method: 'POST',
      body: JSON.stringify({...data, invitationToken: inviteToken}),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((resp) => resp.json())
    .then((resp) => {
      const {success, msg} = resp;

      setResponseMessage(msg);
      setUserCreationSuccess(success);

      if (success) {
        setTimeout(() => navigate('/login'), 10000);
        resetForm();
      }
    })
    .finally(() => {
      setSigningUp(false);
    });
  }

  function renderForm() {
    return (
      <>
        {
          loadingInviteToken ? null : (
            <SignupForm
              submitting={signingUp}
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                company: '',
                phone: '',
                password1: '',
                password2: '',
                ...invitationData,
                termsofservice: false,
              }}
              isInvitedSignup={!!invitationData}
              onSubmit={handleSignup}
            />
          )
        }
      </>
    )
  }

  function renderSignupHeader() {
    return (
      <>
        <Logo />
        <div className="text-4xl font-bold">
          {
            invitationData?.firstName ? (
              `Reply to ${invitationData.firstName} by signing up for fuse`
            ) : (
              'Sign up for fuse'
            )
          }
        </div>
        <p className="text-lg">fuse is a modern communication platform and market data application tailored for the commercial insurance and reinsurance industry: find industry contacts, communicate in real time, better understand the market powered by actual market data.</p>
        <div>
          <div className="font-bold mb-2">Who uses fuse?</div>
          <div>retail brokers</div>
          <div>wholesale brokers</div>
          <div>fac reinsurance brokers</div>
          <div>treaty reinsurance brokers</div>
          <div>insurance underwriters</div>
          <div>excess underwriters</div>
          <div>surplus lines underwriters</div>
          <div>fac reinsurance underwriters</div>
          <div>treaty reinsurance underwriters</div>
        </div>
      </>
    )
  }

  return (
    <div className="relative isolate h-full overflow-y-scroll">
      { loadingInviteToken ? (
        <div className="z-50 bg-black/75 backdrop-blur-sm h-full w-full absolute top-0 left-0 flex items-center justify-center space-x-5 font-semibold">
          <Spinner className="h-8 w-8" />
          <p className="text-2xl">Loading invitation...</p>
        </div>
      ) : null}
      <div className="w-full fixed top-0 left-0 z-50">
        <div className={classNames(
          responseMsg === null ? 'hidden' : '',
          userCreationSuccess ? 'border-green-900 bg-green-700' : 'border-red-900 bg-red-700',
          "transition mx-auto max-w-2xl sm:rounded-b-lg sm:border-2 border-t-0 p-3 text-white font-semibold text-center"
        )}>
          <div>{responseMsg}</div>
        </div>
      </div>

      <div className='block sm:hidden p-4 space-y-10'>
        <div className='space-y-4'>
          {renderSignupHeader()}
        </div>
        <div className='space-y-4'>
          {renderForm()}
        </div>
      </div>

      <div className="sm:flex hidden flex-col justify-center items-center h-full w-full">
        <div className="sm:flex justify-center items-center w-full h-full">
          <div className="bg-gray-800 w-full h-full">
            <div className="flex flex-col justify-center items-center h-full">
              <div className="sm:flex justify-end items-center sm:w-3/4 p-4 sm:p-0 mx-auto">
                <div className="sm:flex flex-col justify-center items-center sm:w-3/4">
                  <div className="space-y-6">
                    {renderSignupHeader()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-900 h-full w-full">
            <div className="flex flex-col justify-center items-center h-full">
              <div className="flex justify-start items-center sm:w-1/2 w-full px-2">
                {renderForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const IntlPhoneNumber = ({field, className}) => {
  const [_field, _meta, helpers] = useField(field.name);

  return (
    <PhoneInput
      defaultCountry='us'
      className={className}
      name={field.name}
      value={field.value}
      hideDropdown
      onChange={helpers.setValue}
      style={{
        '--react-international-phone-border-radius': 0,
        '--react-international-phone-border-color': "gray",
        '--react-international-phone-background-color': "transparent",
        '--react-international-phone-text-color': "white",
        '--react-international-phone-selected-dropdown-item-background-color': "black",
        '--react-international-phone-country-selector-background-color-hover': "black",
      }}
    />
  )
}
