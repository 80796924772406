import * as React from "react";
import {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import AutocompleteClass from "../Autocomplete/AutocompleteClass";
import AutocompleteLob from "../Autocomplete/AutocompleteLob";
import AutocompleteLocation from "../Autocomplete/AutocompleteLocation";
import AutocompleteSector from "../Autocomplete/AutocompleteSector";
import AutocompleteUserProfile from "../Autocomplete/AutocompleteUserProfile";
import useSocket from "../hooks/useSocket";
import {setUser} from "../reducers/userSlice";
import {Alert, AlertDescription} from "../ui/Alert";
import {Badge} from "../ui/Badge";
import {Button} from "../ui/Button";
import {Dialog, DialogContent, DialogFooter, DialogTitle, DialogTrigger} from "../ui/Dialog";
import {Input} from "../ui/Input";
import {Switch} from "../ui/Switch";
import {Textarea} from "../ui/Textarea";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";
import CreateCompanyForm from "./CreateCompanyForm";
import AutocompleteCompany from '../Autocomplete/AutocompleteCompany';
import UserAvatarForm from "./UserAvatarForm";

export function MyUserProfile() {
  const {user} = useSelector(state => state.user);
  const [profile, setProfile] = useState();
  const [editCompany, setEditCompany] = useState(false);
  const [company, setCompany] = useState();
  const [avatarUrl, setAvatarUrl] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showCreateNewCompany, setShowCreateNewCompany] = useState(false);
  const [newCompanyDefaultValues, setNewCompanyDefaultValues] = useState({});
  const [socket] = useSocket();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;

    getProfile();
  }, [socket, user]);

  function getProfile() {
    socket?.emit('profiles:find', {profileId: user.id}, ({success, profile}) => {
      if (!success) return;

      setProfile(profile);
      setCompany(getUserCompanyData(profile));
    });
  }

  function getUserCompanyData(profile) {
    if (!profile?.company) return {};

    return ({
      cashtag: profile.company.cashtag,
      name: profile.company.name,
      avatarUrl: profile.company.avatarUrl,
    });
  }

  function updateUser() {
    socket.emit('user:profile:update', {
      ...profile,
      avatarUrl,
      companyId: company?.id || null,
    }, ({success, msg}) => {
      if (!success) return setErrorMessage(msg);

      setEditCompany(false);

      socket.emit('me', null, (me) => dispatch(setUser(me)));
      getProfile();

      toast.success('You profile has been saved and updated.', DEFAULT_TOAST_CONFIG);
    });
  }

  function renderSaveButton() {
    return (
      <button
        onClick={updateUser}
        className="rounded-md disabled:opacity-50 w-full bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
      >
        Save
      </button>
    )
  }

  if (!profile) return null;

  return (
    <>
      <Dialog open={showCreateNewCompany} onOpenChange={setShowCreateNewCompany}>
        <DialogContent>
          <CreateCompanyForm
            defaultValues={newCompanyDefaultValues}
            onCancel={() => setShowCreateNewCompany(false)}
            onCreated={(company) => {
              setCompany(company);
              setEditCompany(false);
              setShowCreateNewCompany(false);
            }}
          />
        </DialogContent>
      </Dialog>
      <div className='sm:hidden fixed z-50 bottom-14 bg-gray-800 p-2 w-full'>
        {renderSaveButton()}
      </div>
      <div className='p-5 pb-0 lg:w-3/4 2xl:w-1/2'>
        <h2 className='text-xl font-bold space-y-5'>Profile</h2>
        <div className='sm:flex items-center justify-between mt-8'>
          <div className='flex items-center space-x-5'>
            <div className='shrink-0'>
              <UserAvatarForm onSave={(avatarUrl) => setAvatarUrl(avatarUrl)} profile={profile} />
            </div>
            <div className='flex items-center justify-between w-full'>
              <div className='text-2xl font-bold'>
                <div>{profile.firstName} {profile.lastName}</div>
              </div>
            </div>
          </div>
          <div className='hidden sm:block'>{renderSaveButton()}</div>
        </div>
        {
          errorMessage ? (
            <div className="space-y-4 mt-4">
              <Alert variant='destructive'>
                <AlertDescription>{errorMessage}</AlertDescription>
              </Alert>
            </div>
          ) : null
        }
      </div>
      <div className="space-y-6 p-5 lg:w-3/4 2xl:w-1/2">
        <div className="border-b border-gray-600 pb-12">
          <h2 className="text-base my-4 font-semibold leading-7 text-gray-100">General</h2>
          <div className='space-y-5'>
            <div className='space-y-3'>
              <div className='flex items-center'>
                <label htmlFor="first-name" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  First name
                </label>
                <Input
                  type="text"
                  name="first-name"
                  id="first-name"
                  value={profile?.firstName}
                  onChange={({target: {value}}) => setProfile({...profile, firstName: value})}
                  autoComplete="given-name"
                  className="p-2 py-1"
                />
              </div>
              <div className='flex items-center'>
                <label htmlFor="last-name" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Last name
                </label>
                <Input
                  type="text"
                  name="last-name"
                  id="last-name"
                  value={profile?.lastName}
                  onChange={({target: {value}}) => setProfile({...profile, lastName: value})}
                  autoComplete="family-name"
                  className="p-2 py-1"
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="email" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Email
                </label>
                <div className="w-full">
                  {user.email}
                </div>
              </div>
              <div className='flex items-center w-full grow'>
                <label htmlFor="city" className="w-1/3 text-sm font-medium leading-6 text-gray-100">Your city</label>
                <div className="w-full">
                  <AutocompleteLocation
                    displayValue={[profile.locationCity, profile.locationState, profile.locationCountry].filter(l => !!l).join(', ')}
                    onSelect={(value) => {
                      setProfile({...profile, locationCity: value.cityName, locationState: value.regionName, locationCountry: value.countryName});
                    }}
                    noDataPrompt='Type to search for your city, state/provence and country'
                  />
                </div>
              </div>
              <div className="flex">
                <label htmlFor="username" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Privacy
                </label>
                <div className='w-full'>
                  <div className='flex items-center space-x-2 text-sm'>
                    <Switch checked={profile?.isPublic} onCheckedChange={(checked) => setProfile({...profile, isPublic: checked})}/>
                    <div>{profile?.isPublic ? 'Public' : 'Private'}</div>
                  </div>
                  <div className='text-sm text-gray-300 space-y-4 mt-4'>
                    <p>
                      Choosing 'Public' makes your information visible in Fuse and allows others to view the information
                      you share.
                    </p>
                    <p>
                      Choosing 'Private' ensures your information is hidden from searches on Fuse, and only people who
                      you have existing conversations with or send message requests to can see it.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-600 pb-12">
          <h2 className="text-base my-4 font-semibold leading-7 text-gray-100">Professional info</h2>
          <div className='space-y-5'>
            <div className='space-y-3'>
              <div className='flex items-center'>
                <label htmlFor="company" className="w-1/3 block text-sm font-medium leading-6 text-gray-100">
                  Company
                </label>
                <div className='w-full'>
                  <AutocompleteCompany
                    onSelect={(selection) => {
                      const {companyType, companyName, companyId} = selection;

                      if (companyType === 'user') {
                        setProfile({...profile, companyName});
                        setCompany(undefined);
                        return;
                      }

                      if (companyId) {
                        setProfile({...profile, companyName: null, companyId});
                        setCompany({id: companyId, name: companyName});
                        return;
                      }

                      if (companyType === 'data' || companyType === 'new') {
                        setCompany(undefined);
                        setProfile({...profile, companyName});
                      }
                    }}
                    displayValue={company?.name || profile?.companyName}
                    onCreate={(value) => {
                      setShowCreateNewCompany(true);
                      setNewCompanyDefaultValues({name: value.companyName});
                    }}
                  />
                </div>
              </div>
              <div className='flex items-center'>
                <label htmlFor="job-role" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Job role
                </label>
                <Input
                  type="text"
                  name="job-role"
                  id="job-role"
                  value={profile?.jobRole}
                  onChange={({target: {value}}) => setProfile({...profile, jobRole: value})}
                  className="p-2 py-1"
                />
              </div>
              <div className='flex items-center'>
                <label htmlFor="title" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Headline
                </label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  value={profile?.title}
                  onChange={({target: {value}}) => setProfile({...profile, title: value})}
                  className="p-2 py-1"
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="position" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Position
                </label>
                <Input
                  type="text"
                  name="position"
                  id="position"
                  disabled={!profile}
                  value={profile?.position}
                  onChange={({target: {value}}) => setProfile({...profile, position: value})}
                  className="p-2 py-1"
                />
              </div>
              <div className="flex">
                <label htmlFor="position-description" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Position description
                </label>
                <Textarea
                  name="position-description"
                  id="position-description"
                  disabled={!profile}
                  rows={3}
                  placeholder='Describe your position...'
                  value={profile?.positionDescription || ''}
                  onChange={({target: {value}}) => setProfile({...profile, positionDescription: value})}
                  className="p-2 py-1"
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="industry" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Industry
                </label>
                <AutocompleteUserProfile
                  searchable={false}
                  placeholder='Add your industry'
                  displayValue={profile?.industry}
                  onSelect={(value) => {
                    setProfile({...profile, industry: value || null});
                  }}
                  loadData={(query, onComplete) => {
                    return new Promise((resolve) => {
                      onComplete([
                        {label: 'Insurance', value: 'Insurance'},
                        {label: 'Financial services', value: 'Financial services'},
                      ]);

                      resolve();
                    });
                  }}
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="industry-type" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Industry Type
                </label>
                <AutocompleteUserProfile
                  placeholder='Add your industry type'
                  displayValue={profile?.industryType}
                  searchable={false}
                  onSelect={(value) => {
                    setProfile({...profile, industryType: value});
                  }}
                  loadData={(query, onComplete) => {
                    return new Promise((resolve) => {
                      onComplete([
                        {label: 'Property & Casualty', value: 'Property & Casualty'},
                        {label: 'Life & Health', value: 'Life & Health'},
                      ]);

                      resolve();
                    });
                  }}
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="industry" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Sector
                </label>
                <AutocompleteSector defaultValue={profile.sector} onSelect={(newSector) => setProfile({...profile, sector: newSector})} />
              </div>
              <div className="flex items-center">
                <label htmlFor="industry" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Class
                </label>
                <AutocompleteClass defaultValue={profile.class} onSelect={(newClass) => setProfile({...profile, class: newClass})} />
              </div>
              <div className="flex items-center">
                <label htmlFor="market" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Market
                </label>
                <AutocompleteUserProfile
                  searchable={false}
                  placeholder='Add your market'
                  displayValue={profile?.market}
                  onSelect={(value) => {
                    setProfile({...profile, market: value});
                  }}
                  loadData={(query, onComplete) => {
                    return new Promise((resolve) => {
                      onComplete([
                        {label: 'North America', value: 'North America'},
                        {label: 'South America', value: 'South America'},
                        {label: 'UK', value: 'UK'},
                        {label: 'London', value: 'London'},
                        {label: 'Lloyds', value: 'Lloyds'},
                        {label: 'Bermuda', value: 'Bermuda'},
                        {label: 'Europe', value: 'Europe'},
                        {label: 'Asia', value: 'Asia'},
                      ]);

                      resolve();
                    });
                  }}
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="structure" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Structure
                </label>
                <AutocompleteUserProfile
                  placeholder='Add your structure'
                  displayValue={profile?.structure}
                  searchable={false}
                  onSelect={(value) => {
                    setProfile({...profile, structure: value});
                  }}
                  loadData={(query, onComplete) => {
                    return new Promise((resolve) => {
                      onComplete([
                        {label: 'Primary', value: 'Primary'},
                        {label: 'Excess', value: 'Excess'},
                        {label: 'Facultative', value: 'Facultative'},
                        {label: 'Treaty', value: 'Treaty'},
                        {label: 'ART', value: 'ART'},
                      ]);

                      resolve();
                    });
                  }}
                />
              </div>
              <div className="flex items-center">
                <label htmlFor="segment" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Segment
                </label>
                <AutocompleteUserProfile
                  placeholder='Add your segment'
                  searchable={false}
                  displayValue={profile?.insuranceSegment}
                  onSelect={(value) => {
                    setProfile({...profile, insuranceSegment: value});
                  }}
                  loadData={(query, onComplete) => {
                    return new Promise((resolve) => {
                      onComplete([
                        {label: 'Retail', value: 'Retail'},
                        {label: 'Wholesale', value: 'Wholesale'},
                        {label: 'Reinsurance', value: 'Reinsurance'},
                        {label: 'Retro', value: 'Retro'},
                        {label: 'ILS', value: 'ILS'},
                      ]);

                      resolve();
                    });
                  }}
                />
              </div>
              <div className="flex">
                <label htmlFor="lob-specialty" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  LOB Specialty
                </label>
                <div className='flex flex-wrap items-center justify-start w-full gap-2 bg-slate-700 px-1.5 rounded-md py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 border border-gray-800 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6'>
                  {
                    (profile.lobSpecialty || []).map((s) => {
                      return (
                        <Badge
                          removeable
                          onRemove={() => {
                            setProfile({...profile, lobSpecialty: profile.lobSpecialty.filter(lob => lob !== s)})
                          }}
                          className='rounded'
                        >
                          {s}
                        </Badge>
                      );
                    })
                  }
                  <div className='w-[200px]'>
                    <AutocompleteLob
                      selectedValues={profile.lobSpecialty}
                      onSelect={(selectedValue) => {
                        setProfile({...profile, lobSpecialty: [...(profile.lobSpecialty || []), selectedValue]})
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex">
                <label htmlFor="bio" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                  Bio
                </label>
                <Textarea
                  name="bio"
                  id="bio"
                  disabled={!profile}
                  rows={3}
                  placeholder='Describe your position...'
                  value={profile?.bio}
                  onChange={({target: {value}}) => setProfile({...profile, bio: value})}
                  className="p-2 py-1"
                />
              </div>
            </div>
          </div>
        </div>
        <div className='border-b border-gray-600 pb-12'>
          <h2 className="text-base mt-4 font-semibold leading-7 text-gray-100">Email notifications</h2>
          <h2 className="text-sm mb-2 leading-7 text-gray-100">
            When you are offline, you'll receive email notifications when you get a new message to any of your chats.
          </h2>
          <div className='space-y-5 mt-8'>
            <div className="flex">
              <label htmlFor="bio" className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                New messages
              </label>
              <Switch onCheckedChange={() => setProfile({...profile, emailNotifications: !profile.emailNotifications})} checked={profile.emailNotifications} />
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-base my-4 font-semibold leading-7 text-gray-100">Account control</h2>
          <div className='space-y-5'>
            <div className="flex items-center">
              <div className="w-1/3 text-sm font-medium leading-6 text-gray-100">
                Deactivation
              </div>
              <div className='w-full'>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button className='px-0' variant='link'>Delete account</Button>
                  </DialogTrigger>
                  <DialogContent className='p-5'>
                    <DialogTitle>Delete account</DialogTitle>
                    <div className='h-36 py-5'>You are about to delete your account. Are you sure?</div>
                    <DialogFooter>
                      <Button>No don't delete</Button>
                      <Button variant='ghost'>Yes, delete it</Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
