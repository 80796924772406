import {QuestionMarkCircleIcon} from "@heroicons/react/24/outline";
import {Button} from "../ui/Button";

export default function ChartErrorState({message, reload}) {
  return (
    <div className='h-36 relative'>
      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full'>
        <div className='flex flex-col items-center justify-center space-y-4 w-full font-normal'>
          <QuestionMarkCircleIcon className='h-8'/>
          <div className='text-center text-md'>{message}</div>
          <Button size='sm' onClick={reload}>Reload</Button>
        </div>
      </div>
    </div>
  );
}
