import {createSlice} from "@reduxjs/toolkit";

export const DEFAULT_COMMON_FILTERS = {years: [2021, 2022, 2023], lob: ['Fire', 'Allied lines', 'Commercial auto physical damage', 'Aircraft (all perils)', 'Surety']};
export const DEFAULT_TABLE_FILTERS = {...DEFAULT_COMMON_FILTERS, states: []};
export const DEFAULT_CHART_FILTERS = {...DEFAULT_COMMON_FILTERS};

const initialState = {
  chart: {
    filterState: DEFAULT_CHART_FILTERS,
    dpeGrowth: {
      loading: false,
      response: null,
    },
  },
  market: {
    filterState: DEFAULT_TABLE_FILTERS,
  },
};

const dataSlice = createSlice({
  name: 'dataapp',
  initialState,
  reducers: {
    setChartFilterState: (state, action) => {
      state.chart.filterState = action.payload;
    },
    setMarketFilterState: (state, action) => {
      state.market.filterState = action.payload;
    },
    setDpeLoading: (state, action) => {
      state.chart.dpeGrowth.loading = action.payload;
    },
    setDpeGrowthResponse: (state, action) => {
      state.chart.dpeGrowth.response = action.payload;
    }
  }
});

export const {
  setChartFilterState,
  setMarketFilterState,
  setDpeLoading,
  setDpeGrowthResponse,
} = dataSlice.actions;

export default dataSlice.reducer;
