import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
import parsePhoneNumberFromString from "libphonenumber-js";
import debounce from "lodash.debounce";
import {useEffect, useMemo, useState} from "react";
import {PhoneInput} from "react-international-phone";
import Spinner from "../Spinner";
import {Input} from "./Input";

export default function PhoneNumberInput({placeholder, onChange, defaultValue}) {
  const [phoneNumber, setPhoneNumber] = useState(parsePhoneNumberFromString(defaultValue)?.formatInternational());
  const [verifyingPhoneNumber, setVerifyingPhoneNumber] = useState(false);
  const [verificationError, setVerificationError] = useState();

  useEffect(() => {
    setAndVerifyNumber(phoneNumber);
  }, [phoneNumber]);

  const setAndVerifyNumber = useMemo(() => {
    return debounce((value) => {
      if (!value || value.length < 3) return;
      setVerifyingPhoneNumber(true);

      fetch('/api/profiles/verify_phone', {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({phone: value}),
      })
      .then(resp => resp.json())
      .then(({msg, valid}) => {
        setVerificationError(msg);
        onChange(valid, valid ? value : undefined);
      })
      .finally(() => {
        setVerifyingPhoneNumber(false);
      });
    }, 500, {leading: true});
  }, [setVerifyingPhoneNumber, setVerificationError, onChange]);

  function handleInputChange(input) {
    const parsedNumber = parsePhoneNumberFromString(input);

    if (parsedNumber) {
      setPhoneNumber(parsedNumber?.formatInternational());
    } else {
      setPhoneNumber(input);
    }
  }

  const isValid = !!phoneNumber && !verificationError;

  return (
    <div>
      <div className='relative'>
        <div className='relative'>
          <div className='absolute right-2 top-2.5 scale-125'>
            {
              verifyingPhoneNumber ? (
                <Spinner className='h-5'/>
              ) : (
                <>
                  {isValid ? <CheckCircleIcon className='text-green-500 h-5'/> : null}
                  {phoneNumber && verificationError ? <XCircleIcon className='text-red-500 h-5'/> : null}
                </>
              )
            }
          </div>
          <div className='absolute top-0.5 left-2 flex justify-between items-center w-full px-2 pr-5'>
            <PhoneInput
              style={{
                '--react-international-phone-border-radius': 0,
                '--react-international-phone-border-color': "gray",
                '--react-international-phone-background-color': "transparent",
                '--react-international-phone-text-color': "white",
                '--react-international-phone-selected-dropdown-item-background-color': "black",
                '--react-international-phone-country-selector-background-color-hover': "black",
              }}
              value={phoneNumber}
              className='w-full'
              hideDropdown
              onChange={(value) => {
                handleInputChange(value);
              }}
              placeholder={placeholder}
            />
          </div>
          <Input
            type="tel"
            disabled
            className='py-2 text-md pointer-events-none'
          />
        </div>
      </div>
      {verificationError ? <div className='text-sm text-red-400 text-right'>{verificationError}</div> : null}
    </div>
  );
}
