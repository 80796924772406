import {
  FunnelIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useIntersection, useSearchParam} from "react-use";
import {useLocation, useParams} from "wouter";
import Header from "../Dashboard/Header";
import useAppLocation from "../hooks/useAppLocation";
import {setFilterText} from "../reducers/channelGridSlice";
import {setAdhocChannel} from "../reducers/channelsSlice";
import SlideoverNewChannel from "../Slideovers/SlideoverNewChannel";
import {Input} from "../ui/Input";
import {classNames} from "../utils/classes";
import {getRgbString} from "../utils/colors.js";
import ChatGrid from "./ChatGrid";

const INTERSECTION_OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
};

export default function ChatDisplayManager() {
  const [openNewChannelSlideover, setOpenNewChannelSlideover] = useState(false);
  const mobileChannelGridRef = useRef(null);
  const desktopChannelGridRef = useRef(null);
  const [, {activeSpace}] = useAppLocation();
  const mobileIntersection = useIntersection(mobileChannelGridRef, INTERSECTION_OPTIONS);
  const desktopIntersection = useIntersection(desktopChannelGridRef, INTERSECTION_OPTIONS);
  const [, navigate] = useLocation();
  const {activeSpaceId, channelId} = useParams();
  const adhocChannelId = useSearchParam('achid');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeSpaceId) navigate('/chat/all');
  }, [activeSpaceId]);

  useEffect(() => {
    if (!adhocChannelId) return;

    dispatch(setAdhocChannel(adhocChannelId));
  }, [dispatch, adhocChannelId]);

  useEffect(() => {
    if (!mobileIntersection || !desktopIntersection) return;

    if (!mobileIntersection.isIntersecting && channelId) {
      navigate(`/chat/${activeSpaceId}`);
    }
  }, [mobileIntersection, desktopIntersection, channelId, activeSpaceId]);

  if (!activeSpace) return null;

  return (
    <>
      <div ref={desktopChannelGridRef} className='hidden lg:flex flex-col justify-between h-full'>
        <div className='shrink'>
          <Header />
        </div>
        <div className='grow'>
          <ChatGrid />
        </div>
      </div>
      <div className='lg:hidden h-full'>
        <nav className={classNames(channelId ? 'hidden' : 'block space-y-5', 'p-4')}>
          <div className='flex items-center justify-between'>
            <div className='text-lg font-semibold flex items-center space-x-2'>
              {activeSpace.color ? <div className='h-3 w-3 rounded-full' style={{backgroundColor: getRgbString(activeSpace.color)}}></div> : null}
              <span>{activeSpace.name}</span>
            </div>
            <SlideoverNewChannel open={openNewChannelSlideover} setOpen={setOpenNewChannelSlideover}/>
            <PencilSquareIcon className='h-7 w-7' onClick={() => setOpenNewChannelSlideover(true)}/>
          </div>
          <div className='relative'>
            <Input className='h-10' placeholder='Search by name...' onChange={({target: {value}}) => dispatch(setFilterText(value))} />
            <FunnelIcon className='text-gray-100 h-6 mt-2 mr-2 absolute right-0 top-0' />
          </div>
        </nav>
        <div className='h-full overflow-hidden' ref={mobileChannelGridRef}>
          <ChatGrid enableConversationSelector/>
        </div>
      </div>
    </>
  )
}
