import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon, PencilSquareIcon} from "@heroicons/react/24/outline";
import {AcademicCapIcon, PlusIcon} from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";
import React, {Fragment, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import ChannelContext from "../Contexts/ChannelContext";
import useSocket from "../hooks/useSocket";
import UserAvatar from "../UserAvatar";
import {classNames} from "../utils/classes";
import DateFormatter, {dateFormatter} from "../utils/DateFormatter";
import {SendMessage} from "./Editor";
import EmojiDialog from "./EmojiDialog";
import MessageAttachmentViewer from "./MessageAttachmentViewer";
import RichTextEditor from "./RichTextEditor";

import './Message.css';

export default function Message({observe, message, isIntersecting, children}) {
  const messagesContainerRef = useRef(null);
  const addEmojiButtonRef = useRef(null);
  const reactionsBoxRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const {setShowUserInfoSlideover, setShowCompanyInfoSlideover, channelId} = useContext(ChannelContext);
  const [emojimartVisible, showEmojimart] = useState(false);
  const allActiveMembers = useSelector(state => state.memberStatuses);
  const {user: {id: userId}} = useSelector(state => state.user);
  const channel = useSelector(state => state.channels[channelId]);
  const [socket, isSocketConnected] = useSocket();

  const member = allActiveMembers[message.user_id];
  const isAdmin = channel.created_by === message.user_id;

  useEffect(() => {
    if (!messagesContainerRef.current) return;

    function onMentionClick({target}) {
      const mentionData = JSON.parse(target.dataset.lexicalBeautifulMentionData);

      switch (target.dataset.lexicalBeautifulMentionTrigger) {
        case '$':
          setShowCompanyInfoSlideover({companyId: mentionData.id});
          break;
        case '@':
        default:
          setShowUserInfoSlideover({...mentionData, isChannelAdmin: isAdmin});
      }
    }

    const mentions = messagesContainerRef.current.querySelectorAll('[data-lexical-beautiful-mention=\'true\']');
    mentions.forEach((mentionElem) => {
      let userData = mentionElem.dataset.lexicalBeautifulMentionData;
      if (!userData) return;

      mentionElem.addEventListener('click', onMentionClick);
    });

    const links = messagesContainerRef.current.querySelectorAll('a.editor-link[href]');
    links.forEach(linkElem => {
      linkElem.target = '_blank';
      linkElem.rel = 'noreferrer';
      linkElem.classList.add('[overflow-wrap:anywhere]');
    });

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((e) => {
        if (e.isIntersecting && e.intersectionRatio > 0.5) {
          isIntersecting();
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: [0.0, 0.5, 1.0],
    });

    if (observe) {
      observer.observe(messagesContainerRef.current);
    } else {
      observer.unobserve(messagesContainerRef.current);
    }

    return () => {
      observer.disconnect();
      mentions.forEach((mention) => mention.removeEventListener('click', onMentionClick));
    }
  }, [messagesContainerRef, observe]);

  function showUserInfo() {
    setShowUserInfoSlideover({...member, id: member.user_id, isChannelAdmin: isAdmin});
  }

  function react({native}) {
    if (channel.archived) return;

    socket.emit('messages:react', {channelId: message.channel_id, messageId: message.id, emoticon: native}, () => {
      showEmojimart(false);
    });
  }

  function deleteMessage() {
    if (window.confirm('Are you sure you want to delete this message?')) {
      socket.emit('messages:delete', {channelId: message.channel_id, messageId: message.id});
    }
  }

  if (edit) {
    return (
      <div className="border border-orange-700 bg-gradient-to-br from-transparent from-20% to-orange-600/20 m-1">
        <RichTextEditor disableTyping text={message.message}>
          <div className="bg-gray-700 rounded-b-md p-1 text-white flex -mb-0.5 justify-end w-full">
            <SendMessage onSendMessage={({message: rt, message_plain, channel_id}) => {
              socket.emit('messages:edit', {
                messageId: message.id,
                updatedMessageRichText: rt,
                updatedMessagePlainText: message_plain,
                channelId: channel_id,
              }, () => {
                setEdit(false);
              });
            }} icon={<PencilSquareIcon className="h-5 w-5" />} />
          </div>
        </RichTextEditor>
      </div>
    );
  }

  return (
    <div ref={messagesContainerRef} className='relative p-2'>
      { emojimartVisible ? <div className="absolute top-0 left-0 h-full w-full bg-gradient-to-br from-transparent from-20% to-green-600/20 rounded-sm animate-pulse"></div> : null }
      <div>
        <div onClick={showUserInfo} role="button" className="absolute ml-2 mt-3 left-0 top-0 inline-block flex-1 rounded-md overflow-hidden">
          {member ? <UserAvatar className='h-8 w-8' firstName={member.first_name} lastName={member.last_name} avatarUrl={member.avatar_url} /> : null}
          {
            isAdmin ? (
              <Tippy content="Conversation owner" placement="right">
                <div className='z-30 h-4 w-4 scale-75 origin-bottom-left bg-opacity-75 absolute bottom-0 left-0 bg-yellow-500 text-white rounded-bl-lg rounded-tr-lg'>
                  <AcademicCapIcon className="h-3 w-3 mx-auto mt-0.5" />
                </div>
              </Tippy>
            ) : null
          }
        </div>
        <div className={`z-30 border h-2 w-2 ml-1 mt-2 ${member?.status === 'ONLINE' && isSocketConnected ? 'border-green-600 bg-green-500' : 'border-gray-400 bg-gray-500'} absolute top-0 left-0 rounded-full`} />
      </div>
      <div className="pl-10 mb-1">
        <div className="flex justify-between">
          <div className="font-bold">
            <div onClick={showUserInfo}>
              {member?.first_name} {member?.last_name}
            </div>
            <div className="text-xs text-gray-200 italic font-light -mt-1">
              {member?.company_cashtag ? (
                <span className="hover:underline cursor-pointer" onClick={() => {
                  setShowCompanyInfoSlideover({companyId: member?.company_id});
                }}>
                  ${member?.company_cashtag}
                </span>
              ) : null}
              {member?.company_cashtag && member?.job_role ? ' | ' : null}
              {member?.job_role ? <span className="text-xs text-gray-400 font-normal italic" title={member?.job_role}>{member?.job_role}</span> : null}
            </div>
          </div>
          <div className="flex">
            <div>
              <Tippy delay={500} content={dateFormatter(message.inserted_at)} placement="left">
                <span>
                  <DateFormatter options={{ hour: 'numeric', minute: 'numeric' }} dateString={message.inserted_at} />
                </span>
              </Tippy>
            </div>
            <div className="ml-2">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="">
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-0 w-24 origin-top-right rounded-md bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item disabled={message.user_id !== userId}>
                        {({ active, disabled }) => (
                          <a
                            onClick={() => setEdit(true)}
                            className={classNames(
                              active ? 'bg-gray-800 text-gray-100' : 'text-gray-300',
                              disabled ? 'text-gray-500' : '',
                              'block px-2 py-1 text-sm'
                            )}
                          >
                            Edit
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item disabled={message.user_id !== userId}>
                        {({ active, disabled }) => (
                          <a
                            onClick={deleteMessage}
                            className={classNames(
                              active ? 'bg-red-800 text-gray-100' : 'text-gray-300',
                              disabled ? 'text-gray-500' : '',
                              'block px-2 py-1 text-sm'
                            )}
                          >
                            Delete
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="break-words" dangerouslySetInnerHTML={{__html: `${message.message || '&nbsp;'}`}}></div>
          {message.edited ? (
            <Tippy content="This message has been edited">
              <span className="ml-1 italic text-xs text-gray-400">(edited)</span>
            </Tippy>
          ) : null}
        </div>
        <MessageAttachmentViewer attachments={message.attachments} />
      </div>
      <div className="break-all flex flex-wrap" ref={reactionsBoxRef}>
        {
          Object.keys(message.reactions).map((char) => {
            const isUsersReaction = message.reactions[char].includes(userId);
            const reactionCount = message.reactions[char].length;

            if (!reactionCount) return;

            return (
              <Tippy key={`reaction_${char}_${message.id}`} content={(
                <div className="text-sm">
                  {
                    message.reactions[char].map((userId) => (
                      allActiveMembers[userId] ? (
                        <div key={`${userId}_${char}`}>
                          {allActiveMembers[userId].first_name} {allActiveMembers[userId].last_name}
                        </div>
                      ) : null
                    ))
                  }
                  <div className="text-xs">reacted</div>
                </div>
              )}>
                <span onClick={() => react({native: char})} className={classNames(isUsersReaction ? 'bg-blue-500/40 ring-blue-900' : 'bg-gray-600 ring-gray-800', "cursor-pointer block mr-1 rounded-full ring-2 my-1 mx-1 py-0.5 px-2 font-semibold text-xs")}>{char} {message.reactions[char].length}</span>
              </Tippy>
            )
          })
        }
        {
          !channel.archived ? (
            <div ref={addEmojiButtonRef} onClick={() => {
              showEmojimart(true);
            }} className="flex items-center cursor-pointer mr-1 rounded-full ring-2 ring-gray-400 ring-opacity-30 hover:ring-opacity-90 bg-transparent py-0.5 px-2 my-1 mx-1 font-semibold text-xs grayscale hover:grayscale-0">
              <span>😊</span>
              <PlusIcon className="h-3 w-3 ml-0.5" />
            </div>
          ) : null
        }
      </div>
      <EmojiDialog onSelection={react} setOpen={() => showEmojimart(!emojimartVisible)} open={emojimartVisible}/>
      {children && <div>{children}</div>}
    </div>
  );
}
