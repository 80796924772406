import {Link, useParams} from "wouter";
import useSocket from "../hooks/useSocket";
import Spinner from '../Spinner';
import {useEffect, useMemo, useState} from "react";
import {Profile} from "../Switchboard/types";
import {Button} from "../ui/Button";
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  PaperAirplaneIcon,
  UserMinusIcon,
  UserPlusIcon
} from "@heroicons/react/24/outline";
import {toggleContact} from "../utils/fetchers";
import UserAvatar from '../UserAvatar';
import {Alert, AlertDescription, AlertTitle} from "../ui/Alert";
import useCreateDmChannel from "../hooks/useCreateDmChannel";
import useAppSelector from '../hooks/useAppSelector';
import useIsUserAdmin from '../hooks/useIsUserAdmin';

export default function UserProfile() {
  const [socket] = useSocket();
  const {user} = useAppSelector(state => state.user);
  const isAdmin = useIsUserAdmin();
  const [profile, setProfile] = useState<Profile>();
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [hasLoadedProfile, setHasLoadedProfile] = useState(false);
  const createDmChannel = useCreateDmChannel();
  const {userId: profileId} = useParams<{userId: string}>();

  useEffect(loadProfile, [socket?.connected, profileId]);

  function loadProfile() {
    if (!profileId) return;

    setLoadingProfile(true);

    socket?.emit('profiles:find', {profileId}, ({success, profile}) => {
      setLoadingProfile(false);
      setHasLoadedProfile(true);

      if (!success) return setProfile(undefined);

      setProfile(profile);
    });
  }

  const fullName = useMemo(() => {
    if (!profile) return null;

    return `${profile.firstName} ${profile.lastName}`;
  }, [profile]);

  const companyLocation = useMemo(() => {
    if (!profile) return null;

    return [profile.companyCity, profile.companyState, profile.companyCountry].filter(p => !!p).join(', ');
  }, [profile]);

  const userLocation = useMemo(() => {
    if (!profile) return null;

    return [profile.locationCity, profile.locationState, profile.locationCountry].filter(p => !!p).join(', ');
  }, [profile]);

  function toggle(profile: Profile) {
    toggleContact(profile);
    loadProfile();
  }

  function renderProfileOptionButtons(profile: Profile) {
    if (!profile) return null;

    return (
      <div className='flex sm:mt-0 w-full sm:w-auto items-center space-x-2'>
        <Button className='flex items-center space-x-2 w-full' onClick={() => toggle(profile)}>
          {profile.contact ? <UserMinusIcon className='h-5'/> : <UserPlusIcon className='h-5'/>}
          <span>{profile.contact ? 'Remove from' : 'Add to'} Contacts</span>
        </Button>
        <Button className='flex items-center space-x-2 w-full group' disabled={profile.id === user.id} onClick={() => createDmChannel(profile)}>
          <PaperAirplaneIcon className='h-5 group-hover:-rotate-45 transition-all'/>
          <span>Message</span>
        </Button>
      </div>
    )
  }

  return (
    <>
      <div className='sm:hidden fixed bottom-14 py-2 w-full bg-gray-800 px-4'>
        {renderProfileOptionButtons(profile)}
      </div>
      <div className='sticky bg-gray-800 z-50 top-0 '>
        <Link asChild to='/people'>
          <div className='p-5 flex items-center space-x-3 text-lg font-semibold border-b border-gray-700 pb-4'>
            <ChevronLeftIcon className='h-7'/>
            <div>People</div>
          </div>
        </Link>
      </div>
      <div className='space-y-5'>
        {
          loadingProfile && !hasLoadedProfile ? (
            <div className='flex items-center space-x-2 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50'>
              <Spinner className='h-6'/>
              <span>Loading profile...</span>
            </div>
          ) : null
        }
        {
          profile?.id ? (
            <div className='p-5 pt-1 xl:w-2/3 sm:pb-0 pb-20'>
              <div className='sm:flex items-center justify-between sm:space-x-2 mt-5'>
                <div className='flex items-center space-x-4'>
                  <UserAvatar className='rounded-full ring-2 ring-white w-12 sm:w-16 h-12 sm:h-16' firstName={profile.firstName} lastName={profile.lastName} avatarUrl={profile.profilePictureUrl}/>
                  <div className='font-semibold text-xl sm:text-md'>{fullName}</div>
                </div>
                <div className='hidden sm:block'>
                  {renderProfileOptionButtons(profile)}
                </div>
              </div>
              <div className='divide-y divide-gray-500 mt-5 sm:mt-10'>
                {
                  isAdmin ? (
                    <div className='flex items-center justify-between py-4'>
                      <div className='font-bold'>Email</div>
                      {profile.email ? <div className='whitespace-pre text-right'>{profile.email}</div> : <div className='italic'>Not listed</div>}
                    </div>
                  ) : null
                }
                <div className='flex items-center justify-between py-4'>
                  <div className='font-bold'>Personal Location</div>
                  {userLocation ? <div className='whitespace-pre text-right'>{userLocation}</div> : <div className='italic'>Not listed</div>}
                </div>
                <div className='flex items-center justify-between py-4'>
                  <div className='font-bold'>Company</div>
                  {profile.companyName ? <div>{profile.companyName}</div> : <div className='italic'>Not listed</div>}
                </div>
                <div className='flex items-center justify-between py-4'>
                  <div className='font-bold'>Company Location</div>
                  {companyLocation ? <div>{companyLocation}</div> : <div className='italic'>Not listed</div>}
                </div>
                <div className='flex items-center justify-between py-4'>
                  <div className='font-bold'>Role</div>
                  {profile.jobRole ? <div className='whitespace-pre text-right'>{profile.jobRole}</div> : <div className='italic'>Not listed</div>}
                </div>
                <div className='flex items-center justify-between py-4'>
                  <div className='font-bold'>Headline</div>
                  {profile.title ? <div>{profile.title}</div> : <div className='italic'>Not listed</div>}
                </div>
                <div className='flex items-center justify-between py-4'>
                  <div className='font-bold'>Position</div>
                  {profile.position ? <div>{profile.position}</div> : <div className='italic'>Not listed</div>}
                </div>
                <div className='py-4'>
                  <div className='font-bold'>Position Description</div>
                  {
                    profile.positionDescription ? (
                      <div className='mt-2'>{profile.positionDescription}</div>
                    ) : (
                      <div className='italic'>Not listed</div>
                    )
                  }
                </div>
                <div className='flex items-center justify-between py-4'>
                  <div className='font-bold'>Industry</div>
                  {profile.industry ? <div>{profile.industry}</div> : <div className='italic'>Not listed</div>}
                </div>
                <div className='flex items-center justify-between py-4'>
                  <div className='font-bold'>Industry Type</div>
                  {profile.industryType ? <div>{profile.industryType}</div> : <div className='italic'>Not listed</div>}
                </div>
                <div className='flex items-center justify-between py-4'>
                  <div className='font-bold'>Market</div>
                  {profile.market ? <div>{profile.market}</div> : <div className='italic'>Not listed</div>}
                </div>
                <div className='flex items-center justify-between py-4'>
                  <div className='font-bold'>Structure</div>
                  {profile.structure ? <div>{profile.structure}</div> : <div className='italic'>Not listed</div>}
                </div>
                <div className='flex items-center justify-between py-4'>
                  <div className='font-bold'>Segment</div>
                  {profile.insuranceSegment ? <div>{profile.insuranceSegment}</div> : <div className='italic'>Not listed</div>}
                </div>
                <div className='flex items-center justify-between py-4'>
                  <div className='font-bold'>LOB Specialty</div>
                  {profile.lobSpecialty ? <div>{profile.lobSpecialty}</div> : <div className='italic'>Not listed</div>}
                </div>
                <div className='py-4'>
                  <div className='font-bold'>Bio</div>
                  {profile.bio ? <div className='mt-2'>{profile.bio}</div> : <div className='italic'>Not listed</div>}
                </div>
              </div>
            </div>
          ) : (
            <>
              {
                !loadingProfile ? (
                  <div className='mx-10 min-w-[400px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                    <Alert>
                      <AlertTitle>Not Found</AlertTitle>
                      <AlertDescription>This profile could not be found.</AlertDescription>
                      <Link to='~/switchboard/people'>
                        <div className='flex space-x-2 items-center mt-4'>
                          <ArrowLeftIcon className='h-4 w-4'/>
                          <span>Search for profiles</span>
                        </div>
                      </Link>
                    </Alert>
                  </div>
                ) : null
              }
            </>
          )
        }
      </div>
    </>
  )
}

