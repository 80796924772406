import {useContext, useMemo} from "react";
import FilterContext from "../Contexts/FilterContext";
import {CHART_FILTER_IDS} from "../Data";
import {classNames} from "../utils/classes";

export default function PrintSelectedFilters({title, show}) {
  const {filterState, filters} = useContext(FilterContext);
  const activeFilterIds = useMemo(() => CHART_FILTER_IDS.filter((id) => !!filterState[id]), [filterState]);

  if (!filters) return null;

  return (
    <div className={classNames(show ? 'print:block' : 'hidden', 'hidden space-y-5 pt-3')}>
      <div className='text-center text-lg font-semibold text-black'>{title}</div>
      <div className='flex items-center justify-center space-x-3 flex-wrap print:text-black'>
        {
          activeFilterIds.map((id, idx) => {
            return (
              <div key={filters[id]?.label} className='flex items-center space-x-3'>
                {
                  filterState[id]?.length ? (
                    <>
                      <div className='print:text-gray-500 text-gray-300'>{filters[id]?.label}</div>
                      <div className='flex items-center font-semibold space-x-2 print:text-black'>
                        {filterState[id]?.map((data) => <div className='truncate' key={`data_${data}`}>{data}</div>)}
                      </div>
                      {idx < activeFilterIds.length - 1 ? <div>·</div> : null}
                    </>
                  ) : null
                }
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
