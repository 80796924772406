import {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {ArrowRightIcon, NoSymbolIcon, UserCircleIcon} from "@heroicons/react/24/outline";
import {Button} from "../ui/Button";
import Spinner from "../Spinner";
import {toast} from "react-toastify";
import {classNames} from "../utils/classes";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";
import useSocket from "../hooks/useSocket";

const MATCHES_PAGE_SIZE = 5;
const IS_DEV = process.env.NODE_ENV === 'development';

export default function ProfileMatcher({next, scrollTop, ...propsBag}) {
  const [socket] = useSocket();
  const containerRef = useRef(null);
  const {user} = useSelector(state => state.user);
  const [matchedProfiles, setMatchedProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setLoading(true);
    window.fetch('/api/profiles/matches', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
    .then(resp => resp.json())
    .then((resp) => {
      let {profile} = resp;

      if (IS_DEV) {
        profile = new Array(14).fill(profile[0]).map((profile, idx) => ({
          ...profile,
          similarity: Math.max(Math.random(), .2),
        }));
      }

      profile = profile.sort((a, b) => a.similarity < b.similarity ? 1 : -1).map((profile, idx) => {
        if (!IS_DEV) return profile;

        return {
          ...profile,
          lastName: `${profile.lastName} ${idx}`,
        }
      });

      let profiles = [];
      for (let i = 0; i < profile.length; i += MATCHES_PAGE_SIZE) {
        profiles.push(profile.slice(i, i + MATCHES_PAGE_SIZE));
      }

      setMatchedProfiles(profiles);
    }).finally(() => {
      setTimeout(() => setLoading(false), IS_DEV ? 0 : 2000);
    });
  }, []);

  const totalMatchCount = useMemo(() => matchedProfiles?.flat().length, [matchedProfiles]);
  const pages = useMemo(() => Math.ceil(totalMatchCount / MATCHES_PAGE_SIZE), [totalMatchCount]);
  const isLastPage = page + 1 === pages;
  const pageProfiles = matchedProfiles.length ? matchedProfiles[page] : [];

  function claimAndNext(selectedProfile) {
    if (!selectedProfile) return;

    socket.emit('profiles:claim', { profileId: selectedProfile.id, resetSetupHasProvidedProfileInfo: true }, ({success, msg}) => {
      if (!success) {
        toast.error(msg, DEFAULT_TOAST_CONFIG);
        return;
      }

      next({matchedProfile: true, profile: propsBag})();
    });
  }

  function declineAndNext() {
    fetch('/api/profiles/decline', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
    .then(resp => resp.json())
    .then(() => {
      next({matchedProfile: false})();
    });
  }

  function nextPage() {
    if (page < pages) setPage(page + 1);
    scrollTop();
  }

  return (
    <div ref={containerRef}>
      <div className='text-center space-y-4 py-4'>
        <h2 className='text-2xl font-semibold'>Is this you?</h2>
        <div className=''>
          <div>
            We think we’ve found your profile to help you get started quickly.
          </div>
          <div>
            You will be able to update info later if any of this information is incorrect.
          </div>
        </div>
      </div>

      <div className='max-w-[70%] lg:max-w-[50%] mx-auto'>
        {
          loading ? (
            <div className='h-96'>
              <div className='flex space-x-3 min-h-96 items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                <Spinner className='h-5 w-5' />
                <span>Finding match...</span>
              </div>
            </div>
          ) : (
            <>
              {
                matchedProfiles?.length ? (
                  <div className={classNames(totalMatchCount === 1 ? 'md:grid-cols-2' : 'md:grid-cols-2 xl:grid-cols-3', 'grid gap-4 sm:grid-cols-2 grid-cols-1')}>
                    {
                      pageProfiles.map((matchedProfile, idx) => {
                        return (
                          <div key={`${matchedProfile.id}_${idx}`}
                               className={('max-w-[90%] mx-auto w-full relative rounded border-2 hover:border-fuse-orange border-gray-500 p-6 flex flex-col justify-between space-y-2')}>
                            {
                              user.role_type === 'admin' ? (
                                <div className='absolute top-0 right-0 p-1 text-xs pr-2'>Similarity: {Math.round(matchedProfile.similarity * 100)}%</div>
                              ) : null
                            }
                            <UserCircleIcon className='h-14 w-14 mx-auto'/>
                            <div className='text-xl text-center'>{matchedProfile.firstName} {matchedProfile.lastName}</div>
                            {
                              matchedProfile.position || matchedProfile.company ? (
                                <div className='py-5 text-center text-sm'>
                                  {matchedProfile.position}
                                  {matchedProfile.position ? ' of ' : ' '}
                                  {matchedProfile.company}
                                </div>
                              ) : (
                                <div className='py-5 text-center text-sm invisible'>
                                  Nothing
                                </div>
                              )
                            }
                            <Button onClick={() => claimAndNext(matchedProfile)} className='px-1.5 py-1 rounded-full'>Yes, this is me</Button>
                          </div>
                        );
                      })
                    }
                    {
                      page < pages && !isLastPage ? (
                        <div role='button' className='max-w-[90%] mx-auto w-full relative p-6 flex group flex-col justify-center items-center space-y-4' onClick={nextPage}>
                          <div className='border group-hover:border-fuse-orange rounded-full p-5 inline size-20'>
                            <ArrowRightIcon className='group-hover:text-fuse-orange h-10 w-10 mx-auto'/>
                          </div>
                          <p className='text-center'>Show more matches</p>
                        </div>
                      ) : (
                        <div className='max-w-[90%] mx-auto w-full relative rounded border-2 border-gray-500 p-6 flex flex-col justify-between space-y-2'>
                          <NoSymbolIcon className='h-14 w-14 mx-auto'/>
                          <div className='text-xl text-center invisible'>NO MATCH</div>
                          <div className='py-5 text-center text-sm invisible'>
                            Nothing
                          </div>
                          <Button onClick={declineAndNext} className='px-1.5 py-1 rounded-full'>None are me</Button>
                        </div>
                      )
                    }
                  </div>
                ) : (
                  <div className='h-96 flex flex-col space-y-5 justify-center items-center'>
                    <span>There was no match found</span>
                    <Button onClick={declineAndNext}>Add profile data</Button>
                  </div>
                )
              }
              <div className='flex items-center justify-center space-x-2 mt-5'>
                {
                  new Array(pages).fill(0).map((_, idx) => {
                    return (
                      <div onClick={() => setPage(idx)} role='button' key={`page_${idx}`} className={classNames(idx === page ? 'bg-white' : '', 'h-2.5 w-2.5 rounded-full border')}/>
                    )
                  })
                }
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}
