import {useDispatch} from "react-redux";
import {removeAdhocChannel} from './reducers/channelsSlice';
import {Dialog, DialogTitle, DialogContent, DialogDescription, DialogFooter, DialogClose} from './ui/Dialog';
import Chat from './Chat';
import * as React from "react";
import {useState} from "react";
import {Button} from "./ui/Button";
import useAppSelector from "./hooks/useAppSelector";

export default function ChatFloater() {
  const channel = useAppSelector((state) => state.channels[state.channels.adhocChannel?.id]);
  const {hasDraftMessage} = useAppSelector(state => state.channels.adhocChannel);
  const [confirmClose, setConfirmClose] = useState(false);
  const dispatch = useDispatch();

  function closeChannel() {
    if (hasDraftMessage) {
      setConfirmClose(true);
      return;
    }

    onClose();
  }

  function onClose() {
    setConfirmClose(false);
    dispatch(removeAdhocChannel());
  }

  return (
    <>
      <Dialog open={confirmClose} onOpenChange={setConfirmClose}>
        <DialogContent className='p-5 bg-gray-800'>
          <DialogTitle>You haven't sent your message.</DialogTitle>
          <DialogDescription className='h-16'>Are you sure you want to close this conversation window?</DialogDescription>
          <DialogFooter>
            <DialogClose>
              <Button variant='ghost'>Cancel</Button>
            </DialogClose>
            <Button onClick={onClose}>Okay</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {
        channel ? (
          <div className='z-50 transition-y-full fixed flex sm:right-10 bottom-0 w-full md:w-[30rem] sm:h-3/4 h-full sm:max-h-[35em] space-x-5'>
            <Chat
              onClose={closeChannel}
              isOverTree={false}
              isFloating
              over={false}
              channelId={channel.id}
              isOverlay={false}
              isDragging={false}
              shrinkable={true}
              {...channel}
            />
          </div>
        ) : null
      }
    </>
  );
}
