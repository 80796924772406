import {Table as TanTable} from "@tanstack/table-core/build/lib/types";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator
} from "./Dropdown";
import {DropdownMenuTrigger} from "@radix-ui/react-dropdown-menu";
import {Button} from "./Button";
import {AdjustmentsHorizontalIcon} from "@heroicons/react/24/outline";
import * as React from "react";
import {useMemo} from "react";

interface DataTableViewOptionsProps<TData> {
  table: TanTable<TData>
}

export default function DataTableViewOptions<Profile>({table}: DataTableViewOptionsProps<Profile>) {
  const columns = useMemo(() => {
    return table.getAllColumns();
  }, [table]);

  return (
    <div className='flex items-center'>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            className="ml-auto hidden h-8 lg:flex"
          >
            <AdjustmentsHorizontalIcon className="mr-2 h-4 w-4" />
            View
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[200px]">
          <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {
            columns
            .filter((column) => typeof column.accessorFn !== "undefined" && column.getCanHide() && !column.columnDef.meta?.hidden)
            .sort((leftC, rightC) => (leftC.columnDef?.meta?.displayName || leftC.id) > (rightC.columnDef?.meta?.displayName || rightC.id) ? 1 : -1)
            .map((column) => {
              return (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.columnDef?.meta?.displayName || column.id}
                </DropdownMenuCheckboxItem>
              )
            })
          }
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
