import debounce from "lodash.debounce";
import {useContext, useMemo, useRef, useState} from "react";
import FilterContext from "../Contexts/FilterContext";

export default function useDataApi() {
  const abortControllerRef = useRef(null);
  const {filterState} = useContext(FilterContext);
  const [apiErrorMessage, setApiErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const fetchData = useMemo(() => {
    return debounce((path, method = 'POST', skipCache = false) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort('New query in progress');
        setLoading(false);
      }

      const controller = new AbortController();
      abortControllerRef.current = controller;

      setLoading(true);
      setApiErrorMessage(undefined);

      return window.fetch(path, {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method,
        body: method !== 'GET' ? JSON.stringify({filters: filterState, skipCache}) : undefined,
        signal: controller.signal,
      })
      .then(resp => resp.json())
      .then((resp) => {
        if (!resp.hasOwnProperty('success')) {
          resp.success = !resp.error;
        }

        const {msg, data, error, success} = resp;

        if (msg) {
          setApiErrorMessage('Data could not be retrieved.');
          return resp;
        }

        if (!resp.data.statusCode) {
          if (!data || (!data?.length && Array.isArray(data))) {
            setApiErrorMessage('No data to report');
            return resp;
          }

          if (error) {
            setApiErrorMessage(error);
            return resp;
          }

          if (!success) {
            setApiErrorMessage(msg);
            return resp;
          }

          return resp;
        }

        if (resp.data.statusCode === 429) {
          setApiErrorMessage('You have exceeded you request limit.');
        }

        setLoading(false);
        throw new Error('Data API error');
      })
      .then((resp) => {
        setLoading(false);
        return resp;
      });
    }, 1000, { leading: true })
  }, [filterState]);

  return [
    fetchData,
    loading,
    apiErrorMessage,
  ];
}
