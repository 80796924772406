import {ArrowTopRightOnSquareIcon, ArrowUpOnSquareStackIcon} from "@heroicons/react/24/outline";
import {useContext, useEffect, useState} from "react";
import ChannelContext from "../Contexts/ChannelContext";
import useSocket from "../hooks/useSocket";
import CompanyAvatar from "../UserProfile/CompanyAvatar";
import {classNames} from "../utils/classes";
import InfoSlideover from "./InfoSlideover";

export default function CompanyInfoSlideover({show, companyData: initCompanyData}) {
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(false);
  const {setShowCompanyInfoSlideover} = useContext(ChannelContext);
  const [socket] = useSocket();

  useEffect(() => {
    if (!initCompanyData) return;

    setLoading(true);

    socket.emit('company:info', {companyId: initCompanyData.companyId}, ({company}) => {
      setLoading(false);
      setCompanyData(company);
    });
  }, [initCompanyData]);

  function renderProfileData(data) {
    return (
      <h2 className={classNames(data ? '' : 'italic')}>{data ? data : 'Unlisted'}</h2>
    )
  }

  return (
    <InfoSlideover show={show} loading={loading} onLeave={() => console.log('leaving')} close={() => setShowCompanyInfoSlideover(false)}>
      {
        companyData ? (
          <div>
            <div className="flex items-center sticky top-0 backdrop-blur-md bg-transparent p-3 border-b border-gray-600">
              <div className="origin-top-left relative">
                <div className="ring-1 ring-white ml-2 mt-3 left-0 top-0 flex items-center justify-center w-12 h-12 flex-1 overflow-hidden rounded-md">
                  <CompanyAvatar avatarUrl={companyData?.avatar_url} classes='w-12 h-12' cashtag={companyData?.cashtag} />
                </div>
              </div>
              <div className="pl-2">
                <h2 className="grow font-semibold pt-2">{companyData.name}</h2>
                <h2 className="grow font-semibold text-sm text-gray-200">${companyData.cashtag}</h2>
              </div>
            </div>
            <div className="space-y-5 p-4">
              <div className="">
                <h2 className="font-semibold">Bio</h2>
                <div className="capitalize">{renderProfileData(companyData.bio)}</div>
              </div>
              <a href={companyData.website ? companyData.website : ''} target="_blank" className="block truncate">
                <h2 className="font-semibold flex space-x-2 items-center">
                  <span>Website</span>
                  {companyData.website ? <ArrowTopRightOnSquareIcon className="h-4 w-4" /> : null}
                </h2>
                <div>
                  <span className="truncate">{renderProfileData(companyData.website)}</span>
                </div>
              </a>
              <div>
                <h2 className="font-semibold">Fuse Users</h2>
                <div className="capitalize">{renderProfileData(companyData.count)}</div>
              </div>
              <div>
                <h2 className="font-semibold">Name</h2>
                <div>{renderProfileData(companyData.name)}</div>
              </div>
              <div className="">
                <h2 className="font-semibold">Active</h2>
                <div className="capitalize">{renderProfileData(companyData.active ? 'Yes' : 'No')}</div>
              </div>
            </div>
          </div>
        ) : null
      }
    </InfoSlideover>
  )
}
