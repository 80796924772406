import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import useSocket from "../hooks/useSocket";
import Spinner from "../Spinner";
import {Button} from "../ui/Button";
import {Input} from "../ui/Input";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";

export default function ProfileConfirmation({next, ...propsBag}) {
  const [socket] = useSocket();
  const {user} = useSelector(state => state.user);
  const [savingProfile, setSavingProfile] = useState(false);
  const [profile, setProfile] = useState({});
  const [matchedProfile, setMatchedProfile] = useState();

  useEffect(() => {
    if (!user || !socket) return;

    if (user.user_profile_id) {
      socket.emit('profiles:find', {profileId: user.user_profile_id}, ({profile: matchedProfile}) => {
        handleProfile(matchedProfile);
        setMatchedProfile(matchedProfile);
      });
    } else {
      handleProfile();
    }
  }, [user, socket]);

  function handleProfile(matchedProfile) {
    setProfile({
      company: matchedProfile?.companyName || user.company?.name || user.company_name,
      industry: matchedProfile?.industry || user.industry,
      jobRole: matchedProfile?.jobRole || user.job_role,
      segment: matchedProfile?.insuranceSegment || user.insurance_segment,
      title: matchedProfile?.title || user.title,
      lobSpecialty: matchedProfile?.lobSpecialty || user.lob_specialty,
      industryType: matchedProfile?.industryType || user.industry_type,
      position: matchedProfile?.position || user.position,
    });
  }

  function saveProfileData() {
    setSavingProfile(true);

    fetch('/api/profiles/save', {
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      method: 'POST',
      body: JSON.stringify({profile}),
    })
    .then(resp => resp.json())
    .then(({success, msg}) => {
      if (success) {
        toast.success('Your information has been saved!', DEFAULT_TOAST_CONFIG);
        next()();
        return;
      }

      toast.error(msg, DEFAULT_TOAST_CONFIG);
    }).finally(() => {
      setSavingProfile(false);
    });
  }

  return (
    <div className='flex flex-col justify-center items-center space-y-5'>
      <div className='space-y-5'>
        {
          matchedProfile?.id ? (
            <>
              <div className='text-2xl text-center'>Is your information correct?</div>
              <div className='text-sm'>Please update any out of date information.</div>
            </>
          ) : (
            <>
              <div className='text-2xl text-center'>Tell us more about you</div>
              <div className='text-sm'>Sharing your professional information will help you connect more easily.</div>
            </>
          )
        }
      </div>
      <div className='grid sm:grid-cols-2 grid-cols-1 gap-5 sm:w-1/2 w-4/5'>
        <div>
          <label htmlFor='company' className='text-gray-200 text-sm'>Company</label>
          <Input onChange={({target: {value}}) => setProfile({...profile, company: value})} defaultValue={profile?.company} id='company' className='w-full'/>
        </div>
        <div>
          <label htmlFor='industry' className='text-gray-200 text-sm'>Industry</label>
          <Input onChange={({target: {value}}) => setProfile({...profile, industry: value})} defaultValue={profile?.industry} id='industry' className='w-full'/>
        </div>
        <div>
          <label htmlFor='job_role' className='text-gray-200 text-sm'>Job role</label>
          <Input onChange={({target: {value}}) => setProfile({...profile, jobRole: value})} defaultValue={profile?.jobRole} id='job_role' className='w-full'/>
        </div>
        <div>
          <label htmlFor='segment' className='text-gray-200 text-sm'>Segment</label>
          <Input onChange={({target: {value}}) => setProfile({...profile, segment: value})} defaultValue={profile?.segment} id='segment' className='w-full'/>
        </div>
        <div>
          <label htmlFor='title' className='text-gray-200 text-sm'>Title</label>
          <Input onChange={({target: {value}}) => setProfile({...profile, title: value})} defaultValue={profile?.title} id='title' className='w-full'/>
        </div>
        <div>
          <label htmlFor='lob_specialty' className='text-gray-200 text-sm'>LOB Specialty</label>
          <Input onChange={({target: {value}}) => setProfile({...profile, lobSpecialty: value})} defaultValue={profile?.lobSpecialty} id='lob_specialty' className='w-full'/>
        </div>
        <div>
          <label htmlFor='industry_type' className='text-gray-200 text-sm'>Industry Type</label>
          <Input onChange={({target: {value}}) => setProfile({...profile, industryType: value})} defaultValue={profile?.industryType} id='industry_type' className='w-full'/>
        </div>
        <div>
          <label htmlFor='position' className='text-gray-200 text-sm'>Position</label>
          <Input onChange={({target: {value}}) => setProfile({...profile, position: value})} defaultValue={profile?.position} id='position' className='w-full'/>
        </div>
      </div>
      <Button onClick={saveProfileData} disabled={savingProfile} className='flex items-center space-x-2'>
        {savingProfile ? <Spinner className='h-5 w-5' /> : null}
        <span>Save and continue</span>
      </Button>
    </div>
  )
}
