import {useCallback} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "wouter";
import useUserProfileCompletionCalculation from "../UserSetup/useUserProfileCompletionCalculation";

const USER_SETUP_PATH = '/user/setup';
const PEOPLE_PATH = '/switchboard/people';

const INIT_NAV = {
  PEOPLE: 'init-nav-people',
  USER: 'init-nav-user-setup',
}

export default function usePerformInitNavigation() {
  const me = useSelector((state) => state.user);
  const [location, navigate] = useLocation();

  const [profileCompletionLoaded, {percentage: profileCompletionPercentage}] = useUserProfileCompletionCalculation();

  return useCallback(() => {
    const hasShownUserSetup = Boolean(window.sessionStorage.getItem(INIT_NAV.USER));
    const hasShownPeople = Boolean(window.sessionStorage.getItem(INIT_NAV.PEOPLE));

    // send user to user setup if the user has not completed setup or has not been navigated to setup in this session
    if (profileCompletionPercentage < 100 && !hasShownUserSetup) {
      setTimeout(() => window.sessionStorage.setItem(INIT_NAV.USER, true), 0);

      if (location === USER_SETUP_PATH) {
        console.log(`Was navigating to ${USER_SETUP_PATH}, bailing`);
        return;
      }

      return navigate(USER_SETUP_PATH);
    }

    // send the user to the people app if they only have 0 or 1 chats or if they haven't created any of their own
    const hasCreatedChannels = !!me.user.channels.find((c) => c.created_by === me.user.id);
    if ((!me.user.channels.length < 2 || !hasCreatedChannels) && !hasShownPeople) {
      setTimeout(() => window.sessionStorage.setItem(INIT_NAV.PEOPLE, true), 0);

      if (location === PEOPLE_PATH) {
        console.log(`Was navigating to ${PEOPLE_PATH}, bailing`);
        return;
      }

      return navigate(PEOPLE_PATH);
    }

    navigate(location || '/');
  }, [profileCompletionLoaded, me, location]);
}
