import useAppSelector from "../hooks/useAppSelector";
import {useEffect, useMemo, useRef, useState} from "react";
import useSocket from '../hooks/useSocket';
import {dateFormatter} from '../utils/DateFormatter';
import {Company} from "./types";
import UserAvatar from '../UserAvatar';
import {CheckCircleIcon, ClockIcon} from "@heroicons/react/24/outline";
import {Input} from "../ui/Input";
import {useIntersection, useScrolling} from "react-use";
import {Skeleton} from "../ui/Skeleton";
import {removeAdhocChannel, setAdhocChannel} from '../reducers/channelsSlice';
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger} from "../ui/Select";
import {useDispatch} from "react-redux";
import {classNames} from "../utils/classes";
import {Button} from "../ui/Button";

type ChannelPreviewData = {
  id: number,
  messages: {
    id: number,
    messageHtml: string,
    sentAt: string,
  }[],
  members: { [memberId: string]: Member },
}

type Member = {
  id: string,
  avatarUrl: string,
  firstName: string,
  lastName: string,
  profileCompany: string,
  company: Company
}

type FilterType = 'all' | 'active' | 'draft';

const defaultFilters: { [key in FilterType]: string } = {
  all: 'All',
  active: 'Accepted',
  draft: 'Pending',
}

export default function RequestsList() {
  const {user} = useAppSelector(state => state.user);
  const [channelNameQuery, setChannelNameQuery] = useState<string>();
  const channelsById = useAppSelector((state) => state.channels);
  const [channels, setChannels] = useState<any[]>([]);
  const [filter, setFilter] = useState<FilterType>('draft');
  const containerRef = useRef(null);
  const canLoad = !useScrolling(containerRef);
  const [socket] = useSocket();

  useEffect(() => {
    socket?.emit('channels:sent-request:search', {status: filter !== 'all' && filter}, ({channels}: {
      channels: any[],
    }) => {
      setChannels(channels);
    });
  }, [channelsById, channelNameQuery, filter, socket]);

  const filteredChannels = useMemo(() => {
    return channels.filter((channel) => {
      if (!channelNameQuery) return true;
      return !!channel;
    }).map((channel) => {
      const toMember = Object.values(channel.members).find((u: Member) => u.id !== user.id) as unknown as Member;
      const to = `${toMember.firstName} ${toMember.lastName}`;

      channel.show = channelNameQuery ? to.toLowerCase().includes(channelNameQuery) : true;

      return channel;
    });
  }, [channels, channelNameQuery, user]);

  return (
    <div className='h-full w-full overflow-y-auto px-4 py-5' ref={containerRef}>
      <div className='pb-8 flex items-center justify-between'>
        <h2 className='text-xl font-semibold'>Sent requests</h2>
        <div>last 90 days</div>
      </div>
      <div className='flex justify-center'>
        <div className='w-full'>
          <div className='mb-4 flex items-center'>
            <Input className='py-2.5 grow w-full' placeholder='Search requests' onChange={({target: {value}}) => setChannelNameQuery(value)} />
            <div className='shrink'>
              <Select onValueChange={(f: FilterType) => setFilter(f)} value={filter}>
                <SelectTrigger className='space-x-2'>
                  <span>{defaultFilters[filter]}</span>
                </SelectTrigger>
                <SelectContent align='end'>
                  <SelectGroup>
                    { Object.entries(defaultFilters).map(([key, label]) => <SelectItem value={key}>{label}</SelectItem>) }
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className='space-y-10 divide-y divide-slate-700'>
            {
              filteredChannels.map((channel) => {
                return (
                  <div key={channel.id} className={classNames(channel.show ? '' : 'hidden', 'pb-0 py-10 w-full min-h-36')}>
                    <ChannelPreview canLoad={canLoad} channel={channel} />
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

type ChannelPreviewType = {
  channel: any,
  canLoad: boolean,
};

function ChannelPreview({channel, canLoad}: ChannelPreviewType) {
  const {user} = useAppSelector(state => state.user);
  const [channelData, setChannelData] = useState<ChannelPreviewData>();
  const [toMember, setToMember] = useState<Member>()
  const [missingUserAvatar, setMissingUserAvatar] = useState<boolean>(false);
  const messagePreviewRef = useRef(null);
  const [socket] = useSocket();
  const dispatch = useDispatch();
  const intersection = useIntersection(messagePreviewRef, {
    root: null,
    rootMargin: '100% 0px',
    threshold: 0.2,
  });

  useEffect(() => {
    if (!canLoad || channelData || !intersection || !intersection?.isIntersecting) return;

    getChannel();
  }, [socket, user, intersection, canLoad]);

  function getChannel() {
    if (!user) return;

    socket?.emit('channel:get', {channelId: channel.id}, (channel) => {
      const to = channel.members.find(u => u.id !== user.id);
      setToMember(to);

      setTimeout(() => setChannelData(channel), 500);
    });
  }

  const [fullName, lastMessage, lastMessageDateString, companyName] = useMemo(() => {
    if (!channelData || !channelData.messages.length) return [];

    const fullName = `${toMember.firstName} ${toMember.lastName}`;
    const companyName = toMember.profileCompany || toMember.company?.name;
    const lastMessage = channelData?.messages[0];
    const lastMessageDate = dateFormatter(lastMessage.sentAt, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).replace('AM', 'am').replace('PM', 'pm');

    return [fullName, lastMessage, lastMessageDate, companyName];
  }, [channelData, toMember]);

  function openConversation(channelId: number) {
    dispatch(removeAdhocChannel());
    dispatch(setAdhocChannel(channelId));
  }

  return (
    <div key={`channel_${channel.id}`} ref={messagePreviewRef}>
      {
        channelData ? (
          <>
            <div className='flex justify-between space-x-6'>
              <UserAvatar className='h-10 w-10 rounded' firstName={toMember.firstName} lastName={toMember.lastName} avatarUrl={toMember.avatarUrl} />
              <div className='grow space-y-3'>
                <div className='flex items-center justify-between w-full'>
                  <div className='text-lg flex items-center space-x-2 leading-none'>
                    <span className='font-semibold'>{fullName}</span>
                    {
                      companyName ? (
                        <>
                          <div>·</div>
                          <span className='text-sm'>{companyName}</span>
                        </>
                      ) : null
                    }
                  </div>
                  <div className='text-sm'>{lastMessageDateString}</div>
                </div>
                {
                  channelData ? (
                    <div className='border-2 bg-slate-700/20 border-slate-700 rounded p-3 max-h-32 h-20 overflow-y-auto'>
                      {
                        lastMessage?.messageHtml ? (
                          <div dangerouslySetInnerHTML={{__html: lastMessage?.messageHtml}} />
                        ) : (
                          <em>You have have not written a message.</em>
                        )
                      }
                    </div>
                  ) : null
                }
                <div className='flex items-center space-x-2'>
                  {
                    channel.status === 'draft' ? (
                      <div className='flex items-center space-x-1'>
                        <ClockIcon className='h-4 w-4' />
                        <div>Pending</div>
                      </div>
                    ) : (
                      <div className='flex items-center space-x-1'>
                        <CheckCircleIcon className='h-4 w-4' />
                        <div>Accepted</div>
                      </div>
                    )
                  }
                  <div>·</div>
                  <Button variant='link' className='px-0' onClick={() => openConversation(channel.id)}>Open conversation</Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className='flex justify-between space-x-2'>
            <Skeleton className='h-10 w-10' />
            <div className='grow space-y-3'>
              <div className='flex items-center justify-between w-full'>
                <Skeleton className="h-4 w-3/5" />
                <Skeleton className="h-4 w-1/5" />
              </div>
              <Skeleton className="h-32 w-full" />
            </div>
          </div>
        )
      }
    </div>
  )
}
