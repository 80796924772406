import {mkConfig, generateCsv, download} from "export-to-csv";

export function millionsAndBillionsFormatter(val, decimalPlaces = 0) {
  const unit = val / 1_000_000_000 >= 1 ? 1_000_000_000 : 1_000_000;

  const tickVal = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(val / unit);

  return val / 1_000_000_000 >= 1 ? `${tickVal}B` : `${tickVal}M`
}

export function downloadCsv(filename, data) {
  const config = mkConfig({filename, useKeysAsHeaders: true});
  const csv = generateCsv(config)(data);
  download(config)(csv);
}

export function rightAlignTableCell(value) {
  return (
    <div className='text-right'>{value}</div>
  );
}
