import {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import useAppLocation from "../../hooks/useAppLocation";
import useSocket from "../../hooks/useSocket";
import LabelForm from "../../Nav/LabelForm";
import {Checkbox} from "../../ui/Checkbox";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../../ui/Dialog";
import {Input} from "../../ui/Input";
import {classNames} from "../../utils/classes";
import ChatLabel from "../ChatLabel";

export default function AddLabelForm({channel}) {
  const [socket] = useSocket();
  const labels = useSelector(state => state.user?.user?.labels);
  const [tagSearch, setTagSearch] = useState('');
  const [showTagSearch, setShowTagSearch] = useState(false);
  const [showCreateLabelDialog, setShowCreateLabelDialog] = useState(false);
  const [, {activeLabel}] = useAppLocation();

  const [filteredLabels, isExactMatch] = useMemo(() => {
    const unusedLabels = labels.filter(l => !channel.tags.find(t => t.id === l.id));

    if (!tagSearch) return [unusedLabels, false];

    const foundFilters = unusedLabels.filter((label) => {
      return label.name.toLowerCase().includes(tagSearch.toLowerCase());
    });

    const isExactMatch = unusedLabels.find(l => l.name === tagSearch);

    return [foundFilters, isExactMatch];
  }, [labels, tagSearch]);

  function selectLabel(label, checked) {
    checked ? addLabel(label) : removeLabel(label);
  }

  function addLabel(newLabel) {
    setTagSearch('');
    setShowTagSearch(false);
    socket.emit('channels_users_tags:add_tag', {channelId: channel.id, tag: newLabel});
    setShowCreateLabelDialog(false);
  }

  function removeLabel(label) {
    if (isRemoveDisabled(label)) {
      return window.alert(`You cannot remove this label because you are actively viewing "${label.name}"`);
    }

    const {channels_users_tags_id: channelsUsersTagId} = channel.tags.find(t => label.id === t.id);

    if (!channelsUsersTagId) return;

    socket.emit('channels_users_tags:remove_tag', {
      tagId: label.id,
      channelsUsersTagId,
    });
  }

  function isRemoveDisabled(label) {
    return activeLabel?.id === label.id;
  }

  return (
    <div className='p-2 bg-gray-700/80 rounded flex-wrap flex items-center relative gap-1'>
      <Dialog open={showCreateLabelDialog} onOpenChange={setShowCreateLabelDialog}>
        <DialogContent className='p-5'>
          <DialogHeader>
            <DialogTitle>New Label</DialogTitle>
          </DialogHeader>
          <LabelForm defaultName={tagSearch} onSave={addLabel} />
        </DialogContent>
      </Dialog>
      {
        channel.tags.map((label) => {
          return (
            <ChatLabel
              key={`selected_filters_${label.id}`}
              fill
              tagId={label.id}
              icon={true}
              onClickRemove={removeLabel}
              removeDisabled={isRemoveDisabled(label)}
              className='mr-1 truncate shrink-0 bg-slate-700 rounded whitespace-nowrap flex items-center space-x-1 text-sm p-0.5'
            />
          );
        })
      }
      <Input
        onChange={({target: {value}}) => setTagSearch(value)}
        onFocus={() => setShowTagSearch(true)}
        value={tagSearch}
        placeholder='Add label'
        className='inline w-auto border-none ring-0 bg-transparent p-0.5 pl-0 focus:pl-2 shadow-none m-0'
      />
      {
        showTagSearch ? (
          <>
            <div className={classNames('transition-height relative text-sm my-2 w-full')}>
              <div className='space-y-2 w-full'>
                {
                  filteredLabels.map((label) => {
                    return (
                      <div className='flex items-center w-full space-x-2'>
                        <Checkbox checked={false} onCheckedChange={(checked) => selectLabel(label, checked)} />
                        <ChatLabel
                          tagId={label.id}
                          fill={false}
                          icon
                          className='border-none truncate grow rounded whitespace-nowrap flex items-center space-x-1 text-sm px-0.5 py-0.5'
                        />
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className='border-t mt-2 w-full text-sm'>
              <div role='button' className='rounded mt-2 px-3 py-1.5 hover:bg-gray-600 transition-colors' onClick={() => setShowCreateLabelDialog(true)}>
                {!tagSearch || isExactMatch ? 'Create new' : `"${tagSearch}" (create new)`}
              </div>
            </div>
          </>
        ) : null
      }
    </div>
  );
}
