import debounce from "lodash.debounce";
import {useMemo, useState} from "react";
import AutocompleteUserProfile from "./AutocompleteUserProfile";

export default function AutocompleteClass({defaultValue, onSelect: onValueSelected}) {
  const [data, setData] = useState([]);

  const getData = useMemo(() => {
    if (data.length) {
      return (query, onComplete) => {
        new Promise((resolve) => {
          onComplete(query ? data.filter(d => d.value.toLowerCase().includes(query.toLowerCase())) : data);
          resolve();
        }).catch(onComplete);
      }
    }

    return debounce(async (query, onComplete) => {
      return fetch('/api/lookup/classes', {
        method: 'POST',
        body: JSON.stringify({query}),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
      .then(r => r.json())
      .then((data) => {
        const allData = data.map(({naicClassDescription}) => ({
          label: naicClassDescription,
          value: naicClassDescription,
        }));

        setData(allData);
        onComplete(allData);
      })
      .catch(() => {
        onComplete && onComplete();
      });
    }, 500);
  }, []);

  function onSelect(value) {
    onValueSelected(value);
  }

  return (
    <AutocompleteUserProfile
      onSelect={onSelect}
      loadData={getData}
      displayValue={defaultValue}
      noDataPrompt='No matches found'
      placeholder='Add your class...'
    />
  );
}
