import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  user: {
    hasLoaded: false,
    avatar_url: null,
    email: null,
    id: null,
    status: null,
    status_updated_at: null,
    username: null,
    labels: [],
  },
  setup: {
    matchedProfile: null,
    loading: false,
    loaded: false,
  },
  socketId: null,
  server: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      return {
        ...state,
        ...action.payload,
        user: {
          hasLoaded: true,
          ...action.payload.user
        }
      };
    },
    setFoundMatchedProfile: (state, action) => {
      state.setup.matchedProfile = action.payload;
    },
    setLoadingMatchedProfile: (state, action) => {
      if (state.setup.loading && !action.payload) state.setup.loaded = true;
      state.setup.loading = action.payload;
    }
  }
});

export const {
  setUser,
  setFoundMatchedProfile,
  setLoadingMatchedProfile,
} = userSlice.actions;

export default userSlice.reducer;
