import React, {useState} from "react";
import {classNames} from "./utils/classes";

export default function UserAvatar({firstName = '', lastName = '', avatarUrl, className = ''}) {
  const [noImgFound, setNoImgFound] = useState(false);

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  return (
    <>
      {
        noImgFound || !avatarUrl ? (
          <div className={classNames(className, 'uppercase flex items-center justify-center overflow-hidden')} style={{backgroundColor: `${stringToColor(firstName + lastName)}`}}>
            <span className='text-white tracking-tight'>{firstName[0]}{lastName[0]}</span>
          </div>
        ) : (
          <img
            alt={`Profile picture for ${firstName} ${lastName}`}
            onError={() => setNoImgFound(true)}
            className={classNames(className, 'object-cover')}
            src={avatarUrl}
            loading='lazy'
          />
        )
      }
    </>
  );
}
