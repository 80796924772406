import {ChartBarIcon, ChatBubbleLeftRightIcon, Cog6ToothIcon, UsersIcon} from "@heroicons/react/24/outline";
import {useSelector} from "react-redux";
import {Link} from "wouter";
import DropdownProfileOptions from "../Dashboard/DropdownProfileOptions";
import useAppLocation from "../hooks/useAppLocation";
import {classNames} from "../utils/classes";

export default function MobileNav() {
  const {totalUnreadMessages} = useSelector(state => state.global);
  const {user} = useSelector(state => state.user);
  const [activeApp] = useAppLocation();

  const isAdmin = user?.role_type === 'admin';

  return (
    <div className='lg:hidden px-2 py-1 w-full bg-gray-700 flex justify-between gap-3'>
      <Link to='~/chat/all' asChild>
        <div className={classNames(activeApp === 'chat' ? 'bg-gray-600' : '', 'w-full flex flex-col items-center justify-center hover:bg-gray-600 rounded p-1 py-1.5')}>
          <div className='h-6 w-6 relative'>
            <ChatBubbleLeftRightIcon />
            {
              totalUnreadMessages ? (
                <div className='rounded-full p-1 text-center bg-fuse-orange absolute -top-1 -right-2 inline text-xs'>
                  <div className='rounded-full h-1.5 w-1.5 bg-white'></div>
                </div>
              ) : null
            }
          </div>
          <div className='text-xs scale-90'>Conversations</div>
        </div>
      </Link>
      <Link to='~/switchboard/people' asChild>
        <div className={classNames(activeApp === 'switchboard' ? 'bg-gray-600' : '', 'w-full flex flex-col items-center justify-center hover:bg-gray-600 rounded p-1 py-1.5')}>
          <UsersIcon className='h-6 w-6'/>
          <div className='text-xs scale-90'>People</div>
        </div>
      </Link>
      <Link to='~/data' asChild>
        <div className={classNames(activeApp === 'data' ? 'bg-gray-600' : '', 'w-full flex flex-col items-center justify-center hover:bg-gray-600 rounded p-1 py-1.5')}>
          <ChartBarIcon className='h-6 w-6'/>
          <div className='text-xs scale-90'>Data</div>
        </div>
      </Link>
      <div className='w-full'>
        <DropdownProfileOptions contentProps={{side: 'bottom', align: 'end', alignOffset: 20}}>
          <div className='w-full flex flex-col items-center justify-center hover:bg-gray-600 rounded p-1 py-1.5'>
            <Cog6ToothIcon className='h-6 w-6'/>
            <div className='text-xs scale-90'>Profile</div>
          </div>
        </DropdownProfileOptions>
      </div>
    </div>
  );
}
