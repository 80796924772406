import {MagnifyingGlassIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {ChevronUpDownIcon} from "@heroicons/react/24/outline/index.js";
import {useVirtualizer} from "@tanstack/react-virtual";
import {useEffect, useRef, useState} from "react";
import {useKeyPressEvent} from "react-use";
import Spinner from "../Spinner";
import {Button} from "../ui/Button";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/Popover";
import {classNames} from "../utils/classes";

export default function AutocompleteUserProfile({
  displayValue,
  onSelect,
  loadData,
  placeholder,
  className,
  createable,
  createNewValue,
  noDataPrompt = 'No results',
  onSelectClose = true,
  searchable = true,
  children
}) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [focusedItemIndex, setFocusedIndexItem] = useState(-1)
  const parentRef = useRef(null);
  const triggerRef = useRef(null);
  const searchInputRef = useRef(null);
  useKeyPressEvent('ArrowDown', () => handleArrowKeyListNavigation('ArrowDown'));
  useKeyPressEvent('ArrowUp', () => handleArrowKeyListNavigation('ArrowUp'));
  useKeyPressEvent('Enter', () => handleArrowKeyListNavigation('Enter'));
  useKeyPressEvent('Tab', () => handleArrowKeyListNavigation('Tab'));

  useEffect(() => {
    if (open) {
      setLoading(true);

      loadData(query, (data) => {
        const newData = data ? [...data] : [];
        const isExactMatch = query && !!data.find(d => d.label.toLowerCase().trim() === query.toLowerCase().trim());

        if (query && !isExactMatch && createable) {
          newData.push({
            label: `Use "${query}"?`,
            value: createNewValue ? createNewValue(query) : query,
          });
        }

        setLoading(false);
        setData(newData);
      });
    } else {
      setQuery('');
    }
  }, [open, query, loadData]);

  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
  });

  useEffect(() => {
    if (!parentRef.current) return;

    rowVirtualizer.scrollToIndex(focusedItemIndex);
  }, [rowVirtualizer, focusedItemIndex]);

  function onItemClick(value) {
    setOpen(onSelectClose ? false : true);
    onSelect(value);
    setQuery('');

    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      searchInputRef.current.focus();
    }
  }

  function handleSetFocusedIndex(index) {
    setFocusedIndexItem(isNaN(index) ? -1 : index)
  }

  function handleArrowKeyListNavigation(key) {
    if (!open) return;

    const indexes = items.map(i => i.index);

    switch (key) {
      case 'ArrowDown':
        if (focusedItemIndex < Math.min(...indexes)) {
          return handleSetFocusedIndex(indexes[0]);
        }

        if (focusedItemIndex > Math.max(...indexes)) {
          return handleSetFocusedIndex(Math.max(...indexes));
        }

        handleSetFocusedIndex(Math.min(rowVirtualizer.getTotalSize(), focusedItemIndex + 1));
        break;
      case 'ArrowUp':
        if (focusedItemIndex < Math.min(...indexes)) {
          return handleSetFocusedIndex(indexes[0]);
        }

        if (focusedItemIndex > Math.max(...indexes)) {
          return handleSetFocusedIndex(Math.max(...indexes));
        }

        handleSetFocusedIndex(Math.max(focusedItemIndex - 1, -1));
        break;
      case 'Enter':
        const value = data[focusedItemIndex]?.value;
        onItemClick(value);
        triggerRef.current?.blur();
        break;
      case 'Tab':
        setOpen(false);
        break;
    }
  }

  const items = rowVirtualizer.getVirtualItems();

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger ref={triggerRef} asChild>
        {
          children ? children : (
            <Button
              role='combobox'
              variant='outline'
              aria-expanded={open}
              onKeyDown={({key}) => key === 'ArrowDown' && setOpen(true)}
              className={classNames(open ? 'ring-2 ring-orange-500' : '', className || 'truncate bg-slate-700 w-full h-8 rounded-md text-white shadow-sm ring-1 ring-gray-500 placeholder:text-gray-400 border border-gray-800 focus:ring-2 focus:ring-orange-600 sm:text-sm sm:leading-6 px-2 py-0 justify-between', !displayValue && placeholder ? 'text-gray-400' : '')}
            >
              <span className='truncate'>{displayValue || placeholder}</span>
              <div className='flex items-center space-x-2 text-xs shrink-0 opacity-80 text-gray-300'>
                {
                  displayValue ? (
                    <XMarkIcon className='h-5 hover:text-white hover:opacity-100 transition-colors' role='button' onClick={(event) => {
                      event.stopPropagation();
                      onItemClick(undefined);
                    }} />
                  ) : null
                }
                <ChevronUpDownIcon className='h-5' />
              </div>
            </Button>
          )
        }
      </PopoverTrigger>
      {/*<PopoverContent onOpenAutoFocus={(e) => e.preventDefault()} align='start' className="w-[400px] p-0">*/}
      <PopoverContent align='start' className="w-[400px] p-0">
        <>
          {
            searchable ? (
              <div className="flex items-center border-b px-3">
                <MagnifyingGlassIcon className="mr-2 h-4 w-4 shrink-0 opacity-50"/>
                <input
                  ref={searchInputRef}
                  onChange={({target: {value}}) => setQuery(value.trim())}
                  className="flex h-8 w-full focus:border-none rounded-md bg-transparent my-2 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                />
              </div>
            ) : null
          }
          <div>
            <div ref={parentRef} className='max-h-[200px] overflow-y-auto py-2'>
              {
                loading ? (
                  <div className='flex items-center justify-center space-x-2 text-sm py-10'>
                    <Spinner className='h-5' />
                    <span>Loading...</span>
                  </div>
                ) : (
                  <>
                    {
                      !data.length && !query && noDataPrompt ? (
                        <div className='flex items-center justify-center space-x-2 text-sm py-10'>
                          {noDataPrompt}
                        </div>
                      ) : null
                    }
                    <div
                      style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      {
                        items.map((item) => {
                          const {index} = item;
                          const {value, label, subLabel} = data[index];

                          return (
                            <div
                              key={item.index}
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: `${item.size}px`,
                                transform: `translateY(${item.start}px)`,
                              }}
                              title={label}
                              onClick={() => onItemClick(value)}
                            >
                              <ListItem focused={item.index === focusedItemIndex}>
                                <div className='flex items-center justify-between'>
                                  {label}
                                  {subLabel ? <span className='group-hover:text-white italic text-gray-500'>{subLabel}</span> : null}
                                </div>
                              </ListItem>
                            </div>
                          )}
                        )}
                    </div>
                    {
                      <>
                        {
                          createable ? null : (
                            <>
                              {
                                !data.length && query && noDataPrompt ? (
                                  <div className='flex items-center justify-center space-x-2 text-sm py-10'>
                                    No results
                                  </div>
                                ) : null
                              }
                            </>
                          )
                        }
                      </>
                    }
                  </>
                )
              }
            </div>
          </div>
        </>
      </PopoverContent>
    </Popover>
  )
}

function ListItem({focused, onClick, children}) {
  return (
    <div className='px-2' onClick={onClick}>
      <div
        className={classNames(focused ? 'bg-gray-500' : '', 'w-full truncate rounded-sm px-2 py-1.5 text-sm outline-none group hover:bg-gray-500 transition-colors cursor-pointer')}
      >
        {children}
      </div>
    </div>
  );
}
