import {PlusCircleIcon} from "@heroicons/react/24/outline";
import debounce from "lodash.debounce";
import {useMemo, useState} from "react";
import {Badge} from "../ui/Badge";
import AutocompleteUserProfile from "./AutocompleteUserProfile";

export default function AutocompleteLob({onSelect, selectedValues}) {
  const [data, setData] = useState([]);

  function getFilteredData(data) {
    return selectedValues ? data.filter(lob => !selectedValues.includes(lob.value)) : data
  }

  const loadLobs = useMemo(() => {
    if (data.length) {
      return (query, onComplete) => {
        new Promise((resolve) => {
          const newData = getFilteredData(data);
          onComplete(newData.filter(d => d.value.toLowerCase().includes(query.toLowerCase())));
          resolve();
        }).catch(onComplete);
      }
    }

    return debounce((query, onComplete) => {
      return fetch('/api/lookup/lob', {
        method: 'POST',
        body: JSON.stringify({query}),
        headers: new Headers({'Content-Type': 'application/json'}),
      })
      .then((resp) => resp.json())
      .then((data) => {
        const filteredData = getFilteredData(data, query);

        setData(filteredData);
        onComplete(filteredData);
      });
    }, 500);
  }, [data, selectedValues]);

  return (
    <AutocompleteUserProfile
      searchable
      loadData={loadLobs}
      onSelect={(value) => value && onSelect(value)}
      onSelectClose={false}
      placeholder='Add an LOB'
    >
      <div>
        <Badge className='bg-transparent border-dashed border border-gray-300 rounded' role='button'>
          <div className='flex space-x-1'>
            <div>Add an LOB</div>
            <PlusCircleIcon className='h-4' />
          </div>
        </Badge>
      </div>
      {/*<input className='w-56' placeholder='Test search' onChange={({target: {value}}) => setQuery(value)} />*/}
    </AutocompleteUserProfile>
  )
}
