import {EllipsisVerticalIcon} from "@heroicons/react/24/solid";
import {useLocation} from "wouter";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger,
  DropdownMenuTrigger
} from "../ui/Dropdown";

export default function DropdownChartOptions({reload, metricName, onChangeChartStyle, chartTypes, onPrint, loading, onDownload}) {
  const [, navigate] = useLocation();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <EllipsisVerticalIcon className='h-5' />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {
          reload ? (
            <DropdownMenuItem onSelect={reload}>
              Reload
            </DropdownMenuItem>
          ) : null
        }
        {
          metricName ? (
            <DropdownMenuItem onSelect={() => navigate(`/data/metric/${metricName}`)}>
              View data
            </DropdownMenuItem>
          ) : null
        }
        {
          chartTypes?.length ? (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <span>Change style</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {
                    chartTypes.map((name) => {
                      return (
                        <DropdownMenuItem key={name} onSelect={() => onChangeChartStyle(name)}>
                          <span className='capitalize'>{name}</span>
                        </DropdownMenuItem>
                      )
                    })
                  }
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          ) : null
        }
        {
          onPrint ? (
            <DropdownMenuItem disabled={loading} onSelect={onPrint}>
              Print
            </DropdownMenuItem>
          ) : null
        }
        {
          onDownload ? (
            <DropdownMenuItem disabled={loading} onSelect={onDownload}>
              Download .csv
            </DropdownMenuItem>
          ) : null
        }
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
