import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import usePerformInitNavigation from "./hooks/usePerformInitNavigation";
import Spinner from "./Spinner";
import useUserProfileCompletionCalculation from "./UserSetup/useUserProfileCompletionCalculation";

export default function AppLoadNavigationManager() {
  const me = useSelector((state) => state.user);
  const [initNavigationManaged, setInitNavigationManaged] = useState(false);
  const performInitNavigation = usePerformInitNavigation();
  const [profileCompletionLoaded, {percentage: profileCompletionPercentage}] = useUserProfileCompletionCalculation();

  useEffect(() => {
    if (!me.user.hasLoaded || !profileCompletionLoaded || initNavigationManaged) return;

    setInitNavigationManaged(true);

    performInitNavigation();
  }, [me.user.hasLoaded, profileCompletionLoaded, profileCompletionPercentage]);

  if (initNavigationManaged) return null;

  return (<AppLoader />);
}

export function AppLoader() {
  return (
    <div className='h-full w-full'>
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <Spinner className='h-10' />
      </div>
    </div>
  );
}
