import debounce from "lodash.debounce";
import {useMemo, useState} from "react";
import AutocompleteUserProfile from "./AutocompleteUserProfile";

export default function AutocompleteCompany({displayValue, onSelect: onValueSelected, className, onCreate}) {
  const [data, setData] = useState([]);

  const getCompanyData = useMemo(() => {
    if (data.length) {
      return (query, onComplete) => {
        new Promise((resolve) => {
          const response = query ? data.filter(d => d.label.toLowerCase().includes(query.toLowerCase())) : data;
          onComplete(response);
          resolve();
        });
      }
    }

    return debounce(async (query, onComplete) => {
      return fetch('/api/lookup/company', {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
      .then(r => r.json())
      .then((data) => {
        const transformedData = data
        .map((item) => ({
          label: item.companyName,
          subLabel: item.companyCashtag ? `$${item.companyCashtag}` : undefined,
          value: item,
        }));

        setData(transformedData);
        onComplete(transformedData);
      })
      .catch(() => {
        onComplete();
      });
    }, 500);
  }, [data]);

  function onSelect(value) {
    setData([]);
    onValueSelected(value);
  }

  return (
    <AutocompleteUserProfile
      createable
      className={className}
      createNewValue={(companyName) => ({companyName, companyType: 'custom'})}
      onSelect={(value) => {
        if (['custom', 'new'].includes(value?.companyType)) return onCreate({...value, companyType: 'new'});

        onSelect(value);
      }}
      loadData={getCompanyData}
      displayValue={displayValue}
      placeholder='Select company...'
      noDataPrompt='Search for your company or create your own'
    />
  );
}
