import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {setChannels} from "../reducers/channelsSlice";
import {setSpaces} from "../reducers/spacesSlice";
import {setLabels, setTags} from "../reducers/tagsSlice";
import {setUser} from "../reducers/userSlice";
import useSocket from "./useSocket";

export default function useGetMe() {
  const [socket] = useSocket();
  const dispatch = useDispatch();

  return useCallback(() => {
    return new Promise((resolve) => {
      socket.emit('me', null, (me) => {
        dispatch(setUser(me));
        dispatch(setSpaces(me.user.state.spaces));
        dispatch(setChannels(me.user.channels));
        dispatch(setTags(me.user.labels));
        dispatch(setLabels(me.user.labels));
        resolve(me);
      });
    });
  }, [socket]);
}
