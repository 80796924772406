import {ExclamationCircleIcon} from "@heroicons/react/20/solid";
import {useState} from 'react'
import {AtSymbolIcon, CheckCircleIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {toast} from "react-toastify";
import validator from 'email-validator';
import useSocket from "../hooks/useSocket";
import AutocompleteMembers from "../modals/AutocompleteMembers";
import Spinner from "../Spinner";
import UserAvatar from "../UserAvatar";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";

export default function NewChannelForm({setOpen}) {
  const [channelName, setChannelName] = useState('');
  const [validatingChannelName, setValidatingChannelName] = useState(false);
  const [channelDescription, setChannelDescription] = useState('');
  const [errors, setErrors] = useState([]);
  const [invitedUsers, addToInvitedUsers] = useState([]);
  const [invitedEmails, addToInvitedEmails] = useState({});
  const [channelNameIsValid, setChannelNameIsValid] = useState(false);
  const [channelCreationStatus, setChannelCreationStatus] = useState(false);
  const [socket] = useSocket();

  function setAndValidateName(name) {
    setChannelName(name);

    setValidatingChannelName(true);

    if (name && socket) {
      socket.emit('channel:validate_name', {validName: name}, ({valid}) => {
        setChannelNameIsValid(!!valid);
        setTimeout(() => setValidatingChannelName(false), 200);
      });
    }
  }

  function createChatAndClose() {
    setChannelCreationStatus('saving');
    socket.emit('new_channel', {channelName, channelDescription, invitedUsers, invitedEmails}, (resp) => {
      const {success, msg, errors} = resp;

      if (!success) {
        if (errors?.length) {
          setErrors(errors);
        } else {
          toast.error(msg || 'Chat could not be created.', DEFAULT_TOAST_CONFIG);
        }

        setChannelCreationStatus(null);
        return;
      }

      setChannelCreationStatus('saved');
      setOpen(false);
    });
  }

  const hasValidName = channelNameIsValid && channelName;
  const invitedEmailsList = Object.keys(invitedEmails);

  return (
    <div className="pb-16">
      {
        errors.length ? (
          <ul className="bg-red-800/75 ring-2 ring-red-600 text-white text-sm font-bold rounded-lg p-3 pl-8 mt-5 list-disc">
            {
              errors.map((err) => {
                return (<li className="dec">{err}</li>)
              })
            }
          </ul>
        ) : null
      }
      <div className="mt-5">
        <div>
          <label className="block text-sm font-medium text-white text-left">Invitees</label>
          <div className='mb-5 mt-1'>
            {
              !invitedUsers.length && !invitedEmailsList.length ? (
                <p className="italic text-gray-300 text-center text-sm">No invitees yet</p>
              ) : null
            }
            {
              invitedUsers.map((user) => {
                return (
                  <div key={`selected_invitee_${user.id}`} className="flex text-white justify-between items-center shadow-sm py-1">
                    <div className="flex items-center space-x-4">
                      <UserAvatar firstName={user.firstName} lastName={user.lastName} avatarUrl={user.avatarUrl} className='h-7 w-7 rounded-lg shadow-md border border-white' />
                      <span className="truncate">{user.firstName} {user.lastName}</span>
                    </div>
                    <XMarkIcon className="text-white h-5 w-5 hover:text-red-700 cursor-pointer" onClick={() => {
                      addToInvitedUsers(invitedUsers.filter(({id}) => id !== user.id));
                    }} />
                  </div>
                )
              })
            }
            {
              invitedEmailsList.map((email) => {
                return (
                  <div key={`selected_invitee_${email}`} className="flex justify-between items-center shadow-sm py-1 text-white">
                    <div className="flex items-center space-x-4">
                      <AtSymbolIcon className="h-5 w-5 rounded-full shadow-md" />
                      <span className="truncate">{email}</span>
                    </div>
                    <XMarkIcon className="text-white h-5 w-5 hover:text-red-700 cursor-pointer" onClick={() => {
                      const newInvitedEmail = {...invitedEmails};
                      delete newInvitedEmail[email];
                      addToInvitedEmails(newInvitedEmail);
                    }} />
                  </div>
                )
              })
            }
          </div>
          <label className="block text-sm font-medium text-white text-left">Add users or emails</label>
          <AutocompleteMembers
            exclude={invitedUsers}
            allowUnknown
            setSelected={(selected) => {
              addToInvitedUsers([...invitedUsers, selected]);
            }}
            validateUnknown={(query) => {
              return validator.validate(query);
            }}
            selectUnknownMember={(unknownEmail) => {
              addToInvitedEmails({...invitedEmails, [unknownEmail]: unknownEmail});
            }}
          />
        </div>
        <legend className="font-bold flex items-center mt-10 mb-3 space-x-2">
          <span>Customize</span>
          {channelCreationStatus === 'saving' ? <Spinner className="-ml-1 mr-3 h-5 w-5 text-white"/> : null}
          {channelCreationStatus === 'saved' ? <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true"/> : null}
        </legend>
        <div className="flex justify-between">
          <label htmlFor="channel_name" className="block text-sm font-medium leading-6 text-white">
            Name <em className='text-gray-500 text-xs'>[Optional]</em>
          </label>
        </div>
        <div className="mt-2 relative">
          <input
            type="text"
            name="channel_name"
            id="channel_name"
            onChange={({target: {value}}) => setAndValidateName(value)}
            className="bg-transparent block w-full rounded-md py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            placeholder="Name your conversation..."
            aria-describedby="text-optional"
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {
              channelName ? (
                <>
                  {
                    validatingChannelName ? (
                      <Spinner className='h-5 w-5' />
                    ) : (
                      <>
                        {
                          hasValidName ? (
                            <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true"/>
                          ) : (
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                          )
                        }
                      </>
                    )
                  }
                </>
              ) : null
            }
          </div>
        </div>
        <br/>
        <div className="flex justify-between">
          <label htmlFor="channel_description" className="block text-sm font-medium leading-6 text-white">
            Description <em className='text-gray-500 text-xs'>[Optional]</em>
          </label>
        </div>
        <div className="mt-2 relative">
          <input
            type="text"
            maxLength={255}
            name="channel_description"
            id="channel_description"
            onChange={({target: {value}}) => setChannelDescription(value)}
            className="bg-transparent block w-full rounded-md py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
            placeholder="Describe your conversation..."
            aria-describedby="text-optional"
          />
        </div>
      </div>
      <div
        className="z-30 absolute bottom-0 left-0 w-full p-3 px-4 flex justify-end items-center border-t border-gray-800 bg-slate-600">
        <button
          disabled={channelName && !channelNameIsValid}
          onClick={() => createChatAndClose()}
          className="rounded-md disabled:bg-gray-500 disabled:opacity-50 bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
        >
          Create
        </button>
      </div>
    </div>
  );
}
