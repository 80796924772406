import {useState} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import useSocket from "../../hooks/useSocket";
import {Button} from "../../ui/Button";
import {
  Dialog, DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "../../ui/Dialog";
import {DEFAULT_TOAST_CONFIG} from "../../utils/toast";

export default function ArchiveChatForm({channelId, close}) {
  const [socket] = useSocket();
  const channel = useSelector(state => state.channels[channelId]);
  const [archiveConfirmation, setArchiveConfirmation] = useState('');

  function archiveChannel() {
    socket.emit('channel:archive', { channelId }, () => {
      toast.success(`${channel.slug} has been archived.`, DEFAULT_TOAST_CONFIG);
      close();
    });
  }

  return (
    <div className='space-y-2'>
      <div className="grow w-full rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-orange-500">
        <input
          type="text"
          name="archive_channel"
          id="archive_channel"
          aria-autocomplete='none'
          value={archiveConfirmation}
          onChange={({target: {value}}) => setArchiveConfirmation(value)}
          placeholder='Enter conversation name to archive'
          autoComplete='none'
          className="w-full border-0 bg-transparent py-1.5 pl-1 text-white focus:ring-0 sm:text-sm sm:leading-6"
        />
      </div>
      <Dialog onOpenChange={(open) => !open && setArchiveConfirmation('')}>
        <DialogTrigger>
          <button
            disabled={archiveConfirmation !== channel.slug}
            className="flex justify-center space-x-2 rounded-md bg-red-500 disabled:bg-gray-500 disabled:opacity-50 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
          >
            <span className="hidden md:inline-block">Archive</span>
          </button>
        </DialogTrigger>
        <DialogContent className='text-red-400 p-5'>
          <DialogHeader>
            <DialogTitle>Are you sure you want to archive this conversation?</DialogTitle>
            <DialogDescription>This action is irreversible, so please be careful.</DialogDescription>
          </DialogHeader>
          <DialogFooter className='mt-20'>
            <DialogClose>
              <Button variant='outline'>Cancel</Button>
            </DialogClose>
            <Button onClick={archiveChannel} variant='destructive'>Archive</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}
