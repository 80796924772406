import {BuildingOffice2Icon} from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import {useState} from "react";
import {twMerge} from "tailwind-merge";
import {cn} from "../utils/classes";

export default function CompanyAvatar({avatarUrl, cashtag, classes = ''}) {
  const [loadingError, setLoadingError] = useState(false);

  return (
    <Tippy content={`$${cashtag}`}>
      <>
        {
          avatarUrl && !loadingError ? (
            <img
              alt="Company avatar"
              src={avatarUrl}
              className={twMerge(`w-7 h-7 bg-white object-contain rounded-lg`, classes)}
              loading='lazy'
              onError={() => setLoadingError(true)}
            />
          ) : (
            <BuildingOffice2Icon className={cn('w-7 h-7 scale-75 flex justify-center bg-transparent text-white', classes)} />
          )
        }
      </>
    < /Tippy>
  );
}
