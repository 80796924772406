import {
  ArrowLeftOnRectangleIcon,
  ArrowUturnLeftIcon,
  DocumentDuplicateIcon,
  PencilSquareIcon
} from "@heroicons/react/24/outline";
import {useSelector} from "react-redux";
import {useCopyToClipboard} from "react-use";
import {useLocation} from "wouter";
import useSocket from "../hooks/useSocket.js";
import {
  DropdownMenu,
  DropdownMenuContent, DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuSubContent,
  DropdownMenuTrigger
} from "../ui/Dropdown";

export default function DropdownProfileOptions({children, contentProps}) {
  const {user} = useSelector(state => state.user);
  const [socket] = useSocket();
  const [, navigate] = useLocation();
  const [, copy] = useCopyToClipboard();

  function logout() {
    if (!window.confirm('Are you sure you want to logout?')) return;

    fetch('/api/logout', {method: 'POST'}).then(() => {
      socket.disconnect();
      window.location.href = '/login';
    });
  }

  function reset(state = {}) {
    if (!window.confirm('Are you sure?')) return;

    socket.emit('profiles:setup:reset', state, () => {
      window.location.reload();
    });
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          {children}
        </DropdownMenuTrigger>
        <DropdownMenuContent className='min-w-[12em]' {...contentProps}>
          <DropdownMenuLabel>{user.first_name} {user.last_name}</DropdownMenuLabel>
          <DropdownMenuSeparator/>
          <DropdownMenuItem onClick={() => navigate('/user/profile')}>
            Profile
            <DropdownMenuShortcut>
              <PencilSquareIcon className='h-4'/>
            </DropdownMenuShortcut>
          </DropdownMenuItem>
          {
            user.role_type === 'admin' ? (
              <>
                <DropdownMenuItem onClick={() => copy(user.id)}>
                  Copy User ID
                  <DropdownMenuShortcut>
                    <DocumentDuplicateIcon className='h-4'/>
                  </DropdownMenuShortcut>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => reset()}>
                  Reset User Setup - ALL
                  <DropdownMenuShortcut>
                    <ArrowUturnLeftIcon className='h-4' />
                  </DropdownMenuShortcut>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => reset({step: 'PHONE_VER'})}>
                  <span className='pr-2'>Reset User Setup - Phone</span>
                  <DropdownMenuShortcut>
                    <ArrowUturnLeftIcon className='h-4' />
                  </DropdownMenuShortcut>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
              </>
            ) : null
          }
          <DropdownMenuItem onClick={logout}>
            Log Out
            <DropdownMenuShortcut>
              <ArrowLeftOnRectangleIcon className='h-4'/>
            </DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
