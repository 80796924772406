import {
  BookOpenIcon, CircleStackIcon,
  PencilSquareIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import {PaperAirplaneIcon} from "@heroicons/react/24/outline/index.js";
import React, {Fragment, useContext, useMemo, useState} from "react";
import {Dialog as HeadlessDialog, Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {useSelector} from "react-redux";
import {Link, useLocation, useRoute} from "wouter";
import DashboardContext from "../Contexts/DashboardContext";
import useAppLocation from "../hooks/useAppLocation";
import Nav from '../Nav/Nav';
import SlideoverNewChannel from "../Slideovers/SlideoverNewChannel";
import Logo from "../Logo";
import {classNames} from "../utils/classes";

function getNav(user) {
  return [
    {
      to: '/switchboard/people',
      label: 'People',
      icon: UsersIcon,
      show: true,
    },
    {
      to: '/switchboard/contacts',
      label: 'Contacts',
      icon: BookOpenIcon,
      show: true,
    },
    {
      to: '/switchboard/requests',
      label: 'Sent requests',
      icon: PaperAirplaneIcon,
      show: true,
    },
    {
      to: '/switchboard/import',
      label: 'Import',
      icon: CircleStackIcon,
      show: user?.role_type === 'admin',
    },
  ];
}

function SpacesStack({selectConversations = false}) {
  const {user} = useSelector(state => state.user);
  const [showNewChannelForm, setShowNewChannelForm] = useState(false);
  const {toggleShowSidebar} = useContext(DashboardContext);
  const [location] = useLocation();
  const [activeApp] = useAppLocation();

  const isSwitchboard = activeApp === 'switchboard';

  const switchboardNav = useMemo(() => getNav(user), [user]);

  return (
    <>
      <div className="flex grow flex-col overflow-x-hidden overflow-y-auto h-full bg-gray-800 ring-1 ring-white/5">
        <div className="z-50 flex h-16 shrink-0 items-center justify-between sticky top-0 bg-gray-800 w-full px-6">
          <SlideoverNewChannel open={showNewChannelForm} setOpen={setShowNewChannelForm} />
          <PencilSquareIcon className='h-6 w-6' onClick={() => setShowNewChannelForm(true)} />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col">
            {
              isSwitchboard ? (
                <>
                  {
                    switchboardNav.map((nav) => {
                      if (!nav.show) return null;

                      return (
                        <Link key={nav.to} onClick={() => toggleShowSidebar(false)} to={nav.to} asChild>
                          <li className={classNames(location.startsWith(nav.to) ? 'bg-gray-600' : '', 'p-3 hover:bg-gray-600 transition-colors flex items-center space-x-2 cursor-pointer')}>
                            <nav.icon className='h-5' />
                            <span>{nav.label}</span>
                          </li>
                        </Link>
                      );
                    })
                  }
                </>
              ) : (
                <Nav selectConversations={selectConversations} />
              )
            }
          </ul>
        </nav>
      </div>
    </>
  )
}

export default function Sidebar() {
  const {toggleShowSidebar, showSidebar} = useContext(DashboardContext);

  return (
    <div className='h-full w-full'>
      <Transition.Root show={showSidebar} as={Fragment}>
        <HeadlessDialog as="div" className="relative z-50 lg:hidden" onClose={toggleShowSidebar}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <HeadlessDialog.Panel className="relative mr-16 flex w-full flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => toggleShowSidebar()}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <SpacesStack />
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </HeadlessDialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <SpacesStack />
    </div>
  )
}
