import {useEffect, useState} from "react";

export default function useLockedMemo(callback, deps) {
  const [value, setValue] = useState();

  useEffect(() => {
    if (value) return;

    const newValue = callback();
    if (!!newValue) console.log('Setting locked value', newValue);
    setValue(newValue);
  }, [...deps, value]);

  return value;
}
